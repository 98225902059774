.timeline--step--active .timeline--step--dot {
  position: relative;
}
.timeline--step--active .timeline--step--dot::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: glow 2s linear infinite;
  border-radius: 50%;
  background: inherit;
  content: '';
}

.timeline--step {
  font-family: Maison Neue, sans-serif;
  font-weight: 900;
  line-height: 26px;
  letter-spacing: 0.2px;
  font-size: 18px;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 24px;
}
.timeline--step--active {
  color: #fc5c63;
}
.timeline--step--dot {
  background-color: rgba(5, 5, 5, 0.2);
  flex: 0 0 8px;
  align-self: flex-start;
  margin-top: 9px;
  margin-right: 16px;
  border-radius: 8px;
  height: 8px;
}
.timeline--step--line {
  background-color: rgba(5, 5, 5, 0.2);
  position: absolute;
  top: 20px;
  bottom: -30px;
  left: 3px;
  margin-top: 0;
  width: 2px;
}
.timeline--step--dot--active,
.timeline--step--line--active {
  background-color: #fc5c63;
}
.timeline--step--after {
  color: rgba(5, 5, 5, 0.4);
}
.timeline--step--after .timeline--step--dot {
  background-color: rgba(5, 5, 5, 0.2);
}
.timeline--step--after .timeline--step--line {
  background-color: rgba(5, 5, 5, 0.2);
}
.timeline--step:last-child .timeline--step--line {
  display: none;
}
.timeline--step--title {
  color: #fc5c63;
  padding: 16px 0;
  min-height: 48px;
}
.timeline--step--title .timeline--step--content {
  letter-spacing: 0;
  font-family: Maison Neue, sans-serif;
  font-weight: 400;
  line-height: 22px;
  font-size: 14px;
}
.timeline--step--title .timeline--step--line {
  top: calc(50% + 16px);
  margin-top: 6px;
}
.timeline--step--title .timeline--step--divider {
  flex-grow: 1;
  margin-left: 16px;
}

@keyframes glow {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
