.not-found--title {
  font-family: Maison Neue, sans-serif;
  font-weight: 900;
  line-height: 40px;
  letter-spacing: 0.3px;
  font-size: 32px;
  margin-bottom: 16px;
  font-size: 56px;
}
@media only screen and (min-width: 768px) {
  .not-found--title {
    line-height: 48px;
    font-size: 40px;
  }
}
@media screen and (min-width: 768px) {
  .not-found--title {
    font-size: 80px;
  }
}
.not-found--title--number {
  padding-right: 8px;
  vertical-align: middle;
}
.not-found--title--number:last-child {
  padding-right: 0;
  padding-left: 8px;
}

.not-found--content {
  margin-bottom: 32px;
}

.not-found--links--title {
  font-family: Maison Neue, sans-serif;
  font-weight: 900;
  line-height: 26px;
  letter-spacing: 0.2px;
  font-size: 18px;
  margin-bottom: 16px;
}

.not-found--links--list--item {
  margin-bottom: 8px;
  text-decoration: underline;
  color: #0c806b;
}
.not-found--links--list--item:last-child {
  margin-bottom: 0;
}
