.removable-sentence {
  border-top: 1px solid rgba(5, 5, 5, 0.2);
  border-bottom: 1px solid rgba(5, 5, 5, 0.2);
}
@media screen and (min-width: 768px) {
  .removable-sentence {
    border: 0;
  }
}
.removable-sentence--row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  background: #f5f5f5;
}
.removable-sentence--row--label {
  background: #0c806b;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  color: #fff;
}
.removable-sentence--row--value {
  padding-right: 8px;
  width: 32px;
}
.removable-sentence--content {
  display: flex;
  position: relative;
}
.removable-sentence--content--position {
  flex: 0 0 32px;
  border-radius: 8px 0 0 8px;
  background: #0c806b;
  padding-top: 16px;
  text-align: center;
  color: #fff;
}
.removable-sentence--content--sentence {
  flex-grow: 1;
  border: 1px solid rgba(5, 5, 5, 0.2);
  border-left: 0;
  border-radius: 0 8px 8px 0;
  padding: 8px 40px 8px 16px;
}
.removable-sentence--content--icon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  padding: 8px;
}
