.sentence--action,
.sentence--list {
  position: relative;
  border-top: 1px solid rgba(5, 5, 5, 0.2);
  border-bottom: 1px solid rgba(5, 5, 5, 0.2);
  padding: 16px;
  padding-left: 48px;
}
@media screen and (min-width: 768px) {
  .sentence--action,
  .sentence--list {
    border-right: 1px solid rgba(5, 5, 5, 0.2);
    border-radius: 8px;
    padding: 16px;
    padding-left: 56px;
  }
}

.sentence--action:after,
.sentence--list--position--number {
  display: flex;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0;
  justify-content: center;
  background: #0c806b;
  width: 32px;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .sentence--action:after,
  .sentence--list--position--number {
    border-radius: 8px 0 0 8px;
  }
}

.sentence {
  position: relative;
  transition: right linear 300ms;
  line-height: 40px;
}
.sentence a {
  text-decoration: underline;
}
@media screen and (min-width: 768px) {
  .sentence {
  }
}
.sentence--with-label {
  margin-bottom: 16px;
  line-height: 24px;
}
.sentence--error {
  letter-spacing: 0;
  font-family: Maison Neue, sans-serif;
  font-weight: 400;
  line-height: 22px;
  font-size: 14px;
  color: #e52626;
}
.sentence--action {
  margin-right: 0;
  margin-left: 0;
  border-style: dashed;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media screen and (min-width: 768px) {
  .sentence--action {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
.sentence--action:after {
  align-items: center;
  content: '+';
}
.sentence--label {
  padding-top: 8px;
  line-height: 0;
}
@media screen and (min-width: 1024px) {
  .sentence--last {
    white-space: nowrap;
  }
}
.sentence--list {
  margin-right: 0;
  margin-left: 0;
  padding: 0;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .sentence--list {
    padding: 16px 48px;
    overflow: initial;
  }
}
.sentence--list--position {
  position: relative;
  background: #f5f5f5;
  height: 32px;
}
@media screen and (min-width: 768px) {
  .sentence--list--position {
    position: absolute;
    top: 0;
    bottom: -1px;
    left: 0;
    background: initial;
    width: 32px;
    height: initial;
  }
}
.sentence--list--position--number {
  bottom: 0;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .sentence--list--position--number {
    align-items: initial;
    padding-top: 16px;
  }
}
.sentence--list--position + .sentence--list--content {
  padding: 16px;
}
@media screen and (min-width: 768px) {
  .sentence--list--position + .sentence--list--content {
    padding: 0;
  }
}
.sentence--helper {
  position: absolute;
  right: 0;
  bottom: 4px;
}

.sentence--delete {
  display: flex;
  position: absolute;
  top: -1px;
  bottom: 24px;
  left: 100%;
  align-items: center;
  justify-content: center;
  transition: left linear 300ms;
  background: #e52626;
  padding: 16px;
  width: 50%;
  color: #fff;
}

.sentences--delete--wrapper {
  position: relative;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .sentences--delete--wrapper {
    overflow: visible;
  }
}
