.bubble-chat {
  display: flex;
}
.bubble-chat--avatar {
  flex: 0 0;
  margin-right: 8px;
}
.bubble-chat--avatar--hidden {
  min-width: 32px;
}
@media screen and (min-width: 768px) {
  .bubble-chat--avatar--hidden {
    min-width: 48px;
  }
}
.bubble-chat--avatar--content {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  line-height: 56px;
  color: #f5f5f5;
  font-size: 32px;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--white {
  background-color: #050505;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--facebook {
  background-color: #4267b2;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--twitter {
  background-color: #1da1f2;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--whatsapp {
  background-color: #25d366;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--primary-1 {
  background-color: #006855;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--primary-1-l {
  background-color: #0c806b;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--primary-1-d {
  background-color: #006855;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--primary-1-20 {
  background-color: #0c806b;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--primary-1-30 {
  background-color: #0c806b;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--primary-1-40 {
  background-color: #0c806b;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--primary-1-60 {
  background-color: #0c806b;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--primary-1-80 {
  background-color: #0c806b;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--primary-2 {
  background-color: #42b491;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--primary-2-l {
  background-color: #8acab7;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--primary-2-d {
  background-color: #348f73;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--neutral-1 {
  background-color: #000000;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--neutral-1-l {
  background-color: rgba(5, 5, 5, 0.2);
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--neutral-1-d {
  background-color: #000000;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--neutral-1-20 {
  background-color: #050505;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--neutral-1-30 {
  background-color: #050505;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--neutral-1-40 {
  background-color: #050505;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--neutral-1-60 {
  background-color: #050505;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--neutral-1-80 {
  background-color: #050505;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-1 {
  background-color: #ee040f;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-2 {
  background-color: #fa2618;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-3 {
  background-color: #ff5632;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-4 {
  background-color: #ffb26b;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-4-20 {
  background-color: #ffe7d1;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-4-30 {
  background-color: #ffe7d1;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-4-40 {
  background-color: #ffe7d1;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-4-60 {
  background-color: #ffe7d1;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-4-80 {
  background-color: #ffe7d1;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-5 {
  background-color: #ff5632;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-1-l {
  background-color: #fc5c63;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-2-l {
  background-color: #fc847c;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-3-l {
  background-color: #ffaa98;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-4-l {
  background-color: #ffe7d1;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-5-l {
  background-color: #ffaa98;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-1-d {
  background-color: #ee040f;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-2-d {
  background-color: #fa2618;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-3-d {
  background-color: #ff5632;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-4-d {
  background-color: #ffb26b;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--accent-5-d {
  background-color: #ff5632;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--success {
  background-color: #0c5f4f;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--success-l {
  background-color: #128c74;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--success-d {
  background-color: #063229;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--warning {
  background-color: #e3401b;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--warning-d {
  background-color: #e3401b;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--warning-l {
  background-color: #ff8867;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--error {
  background-color: #c70808;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--error-d {
  background-color: #c70808;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--error-l {
  background-color: #e52626;
}

.bubble-chat--avatar--string .bubble-chat--avatar--content--inherit {
  background-color: inherit;
}

.bubble-chat--container .bubble-chat--splitted {
  display: block;
  margin-left: 40px;
}
@media screen and (min-width: 768px) {
  .bubble-chat--container .bubble-chat--splitted {
    display: flex;
    align-items: flex-start;
    margin-left: 56px;
  }
}
.bubble-chat--container .bubble-chat--splitted .bubble-chat--avatar {
  display: inline-block;
  margin-bottom: 8px;
  border: 1px solid rgba(5, 5, 5, 0.2);
  border-radius: 16px;
  padding: 24px;
}
@media screen and (min-width: 768px) {
  .bubble-chat--container .bubble-chat--splitted .bubble-chat--avatar {
    margin-right: 16px;
    margin-bottom: 0;
  }
}
