.action-card {
  display: flex;
  flex-grow: 1;
  max-width: 100%;
}
.action-card--pushed-content,
.action-card--description {
  margin-bottom: 8px;
}
@media screen and (min-width: 768px) {
  .action-card--pushed-content,
  .action-card--description {
    margin-bottom: 16px;
  }
}
.action-card--divider {
  margin-bottom: 16px;
}
.action-card--data {
  margin-bottom: 80px;
}
@media screen and (min-width: 768px) {
  .action-card--data {
    margin-bottom: 88px;
  }
}
.action-card--data--row {
  margin-bottom: 8px;
}
.action-card--option-number {
  margin-top: 2px;
}
.action-card--title {
  margin-bottom: 4px;
}
.action-card--pushed-content {
  display: flex;
}
.action-card--pushed-content--emoji {
  display: flex;
  align-items: center;
  margin-right: 16px;
}
@media screen and (min-width: 768px) {
  .action-card--pushed-content--emoji {
    margin-right: 24px;
  }
}
.action-card--pushed-content--amount {
  flex-grow: 1;
}
.action-card--pushed-content--icon {
  align-self: center;
  margin-left: 16px;
  cursor: pointer;
}
.action-card--pushed-content--icon--content {
  display: flex;
  margin-bottom: 32px;
  cursor: pointer;
}
.action-card--pushed-content--icon--content--text {
  margin-left: 16px;
}
.action-card--button {
  position: absolute;
  right: 16px;
  bottom: 16px;
  left: 16px;
}
@media screen and (min-width: 768px) {
  .action-card--button {
    right: 24px;
    bottom: 24px;
    left: 24px;
  }
}
.action-card--button-middle .action-card--button {
  position: initial;
  right: initial;
  bottom: initial;
  left: initial;
  margin-bottom: 16px;
}
@media screen and (min-width: 768px) {
  .action-card--button-middle .action-card--button {
    margin-bottom: 32px;
  }
}
.action-card--button-middle .action-card--description {
  margin-bottom: 0;
}
.action-card--button-middle .action-card--data {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .action-card--button-middle .action-card--data {
    margin-bottom: 0;
  }
}
.action-card--capacity .action-card--button {
  position: initial;
  right: initial;
  bottom: initial;
  left: initial;
}
