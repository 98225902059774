.editable-input--white .editable-input--field--currency {
  border-bottom-color: #fff;
}

.editable-input--facebook .editable-input--field--currency {
  border-bottom-color: #4267b2;
}

.editable-input--twitter .editable-input--field--currency {
  border-bottom-color: #1da1f2;
}

.editable-input--whatsapp .editable-input--field--currency {
  border-bottom-color: #25d366;
}

.editable-input--primary-1 .editable-input--field--currency {
  border-bottom-color: #0c806b;
}

.editable-input--primary-1-l .editable-input--field--currency {
  border-bottom-color: #d2f8d1;
}

.editable-input--primary-1-d .editable-input--field--currency {
  border-bottom-color: #006855;
}

.editable-input--primary-1-20 .editable-input--field--currency {
  border-bottom-color: rgba(12, 128, 107, 0.2);
}

.editable-input--primary-1-30 .editable-input--field--currency {
  border-bottom-color: rgba(12, 128, 107, 0.3);
}

.editable-input--primary-1-40 .editable-input--field--currency {
  border-bottom-color: rgba(12, 128, 107, 0.4);
}

.editable-input--primary-1-60 .editable-input--field--currency {
  border-bottom-color: rgba(12, 128, 107, 0.6);
}

.editable-input--primary-1-80 .editable-input--field--currency {
  border-bottom-color: rgba(12, 128, 107, 0.8);
}

.editable-input--primary-2 .editable-input--field--currency {
  border-bottom-color: #8acab7;
}

.editable-input--primary-2-l .editable-input--field--currency {
  border-bottom-color: #e8f4f1;
}

.editable-input--primary-2-d .editable-input--field--currency {
  border-bottom-color: #42b491;
}

.editable-input--neutral-1 .editable-input--field--currency {
  border-bottom-color: #050505;
}

.editable-input--neutral-1-l .editable-input--field--currency {
  border-bottom-color: #f5f5f5;
}

.editable-input--neutral-1-d .editable-input--field--currency {
  border-bottom-color: #000000;
}

.editable-input--neutral-1-20 .editable-input--field--currency {
  border-bottom-color: rgba(5, 5, 5, 0.2);
}

.editable-input--neutral-1-30 .editable-input--field--currency {
  border-bottom-color: rgba(5, 5, 5, 0.3);
}

.editable-input--neutral-1-40 .editable-input--field--currency {
  border-bottom-color: rgba(5, 5, 5, 0.4);
}

.editable-input--neutral-1-60 .editable-input--field--currency {
  border-bottom-color: rgba(5, 5, 5, 0.6);
}

.editable-input--neutral-1-80 .editable-input--field--currency {
  border-bottom-color: rgba(5, 5, 5, 0.8);
}

.editable-input--accent-1 .editable-input--field--currency {
  border-bottom-color: #fc5c63;
}

.editable-input--accent-2 .editable-input--field--currency {
  border-bottom-color: #fc847c;
}

.editable-input--accent-3 .editable-input--field--currency {
  border-bottom-color: #ffaa98;
}

.editable-input--accent-4 .editable-input--field--currency {
  border-bottom-color: #ffe7d1;
}

.editable-input--accent-4-20 .editable-input--field--currency {
  border-bottom-color: rgba(255, 231, 209, 0.2);
}

.editable-input--accent-4-30 .editable-input--field--currency {
  border-bottom-color: rgba(255, 231, 209, 0.3);
}

.editable-input--accent-4-40 .editable-input--field--currency {
  border-bottom-color: rgba(255, 231, 209, 0.4);
}

.editable-input--accent-4-60 .editable-input--field--currency {
  border-bottom-color: rgba(255, 231, 209, 0.6);
}

.editable-input--accent-4-80 .editable-input--field--currency {
  border-bottom-color: rgba(255, 231, 209, 0.8);
}

.editable-input--accent-5 .editable-input--field--currency {
  border-bottom-color: #ffaa98;
}

.editable-input--accent-1-l .editable-input--field--currency {
  border-bottom-color: #fff2f3;
}

.editable-input--accent-2-l .editable-input--field--currency {
  border-bottom-color: #fdb3ae;
}

.editable-input--accent-3-l .editable-input--field--currency {
  border-bottom-color: #ffd4cb;
}

.editable-input--accent-4-l .editable-input--field--currency {
  border-bottom-color: #fff4eb;
}

.editable-input--accent-5-l .editable-input--field--currency {
  border-bottom-color: #ffbfb2;
}

.editable-input--accent-1-d .editable-input--field--currency {
  border-bottom-color: #ee040f;
}

.editable-input--accent-2-d .editable-input--field--currency {
  border-bottom-color: #fa2618;
}

.editable-input--accent-3-d .editable-input--field--currency {
  border-bottom-color: #ff5632;
}

.editable-input--accent-4-d .editable-input--field--currency {
  border-bottom-color: #ffb26b;
}

.editable-input--accent-5-d .editable-input--field--currency {
  border-bottom-color: #ff5632;
}

.editable-input--success .editable-input--field--currency {
  border-bottom-color: #128c74;
}

.editable-input--success-l .editable-input--field--currency {
  border-bottom-color: #ddefea;
}

.editable-input--success-d .editable-input--field--currency {
  border-bottom-color: #0c5f4f;
}

.editable-input--warning .editable-input--field--currency {
  border-bottom-color: #ff8867;
}

.editable-input--warning-d .editable-input--field--currency {
  border-bottom-color: #e3401b;
}

.editable-input--warning-l .editable-input--field--currency {
  border-bottom-color: #ffb190;
}

.editable-input--error .editable-input--field--currency {
  border-bottom-color: #e52626;
}

.editable-input--error-d .editable-input--field--currency {
  border-bottom-color: #c70808;
}

.editable-input--error-l .editable-input--field--currency {
  border-bottom-color: #ff7676;
}

.editable-input--inherit .editable-input--field--currency {
  border-bottom-color: inherit;
}

.editable-input--white .editable-input--field--icon {
  border-bottom-color: #fff;
}

.editable-input--facebook .editable-input--field--icon {
  border-bottom-color: #4267b2;
}

.editable-input--twitter .editable-input--field--icon {
  border-bottom-color: #1da1f2;
}

.editable-input--whatsapp .editable-input--field--icon {
  border-bottom-color: #25d366;
}

.editable-input--primary-1 .editable-input--field--icon {
  border-bottom-color: #0c806b;
}

.editable-input--primary-1-l .editable-input--field--icon {
  border-bottom-color: #d2f8d1;
}

.editable-input--primary-1-d .editable-input--field--icon {
  border-bottom-color: #006855;
}

.editable-input--primary-1-20 .editable-input--field--icon {
  border-bottom-color: rgba(12, 128, 107, 0.2);
}

.editable-input--primary-1-30 .editable-input--field--icon {
  border-bottom-color: rgba(12, 128, 107, 0.3);
}

.editable-input--primary-1-40 .editable-input--field--icon {
  border-bottom-color: rgba(12, 128, 107, 0.4);
}

.editable-input--primary-1-60 .editable-input--field--icon {
  border-bottom-color: rgba(12, 128, 107, 0.6);
}

.editable-input--primary-1-80 .editable-input--field--icon {
  border-bottom-color: rgba(12, 128, 107, 0.8);
}

.editable-input--primary-2 .editable-input--field--icon {
  border-bottom-color: #8acab7;
}

.editable-input--primary-2-l .editable-input--field--icon {
  border-bottom-color: #e8f4f1;
}

.editable-input--primary-2-d .editable-input--field--icon {
  border-bottom-color: #42b491;
}

.editable-input--neutral-1 .editable-input--field--icon {
  border-bottom-color: #050505;
}

.editable-input--neutral-1-l .editable-input--field--icon {
  border-bottom-color: #f5f5f5;
}

.editable-input--neutral-1-d .editable-input--field--icon {
  border-bottom-color: #000000;
}

.editable-input--neutral-1-20 .editable-input--field--icon {
  border-bottom-color: rgba(5, 5, 5, 0.2);
}

.editable-input--neutral-1-30 .editable-input--field--icon {
  border-bottom-color: rgba(5, 5, 5, 0.3);
}

.editable-input--neutral-1-40 .editable-input--field--icon {
  border-bottom-color: rgba(5, 5, 5, 0.4);
}

.editable-input--neutral-1-60 .editable-input--field--icon {
  border-bottom-color: rgba(5, 5, 5, 0.6);
}

.editable-input--neutral-1-80 .editable-input--field--icon {
  border-bottom-color: rgba(5, 5, 5, 0.8);
}

.editable-input--accent-1 .editable-input--field--icon {
  border-bottom-color: #fc5c63;
}

.editable-input--accent-2 .editable-input--field--icon {
  border-bottom-color: #fc847c;
}

.editable-input--accent-3 .editable-input--field--icon {
  border-bottom-color: #ffaa98;
}

.editable-input--accent-4 .editable-input--field--icon {
  border-bottom-color: #ffe7d1;
}

.editable-input--accent-4-20 .editable-input--field--icon {
  border-bottom-color: rgba(255, 231, 209, 0.2);
}

.editable-input--accent-4-30 .editable-input--field--icon {
  border-bottom-color: rgba(255, 231, 209, 0.3);
}

.editable-input--accent-4-40 .editable-input--field--icon {
  border-bottom-color: rgba(255, 231, 209, 0.4);
}

.editable-input--accent-4-60 .editable-input--field--icon {
  border-bottom-color: rgba(255, 231, 209, 0.6);
}

.editable-input--accent-4-80 .editable-input--field--icon {
  border-bottom-color: rgba(255, 231, 209, 0.8);
}

.editable-input--accent-5 .editable-input--field--icon {
  border-bottom-color: #ffaa98;
}

.editable-input--accent-1-l .editable-input--field--icon {
  border-bottom-color: #fff2f3;
}

.editable-input--accent-2-l .editable-input--field--icon {
  border-bottom-color: #fdb3ae;
}

.editable-input--accent-3-l .editable-input--field--icon {
  border-bottom-color: #ffd4cb;
}

.editable-input--accent-4-l .editable-input--field--icon {
  border-bottom-color: #fff4eb;
}

.editable-input--accent-5-l .editable-input--field--icon {
  border-bottom-color: #ffbfb2;
}

.editable-input--accent-1-d .editable-input--field--icon {
  border-bottom-color: #ee040f;
}

.editable-input--accent-2-d .editable-input--field--icon {
  border-bottom-color: #fa2618;
}

.editable-input--accent-3-d .editable-input--field--icon {
  border-bottom-color: #ff5632;
}

.editable-input--accent-4-d .editable-input--field--icon {
  border-bottom-color: #ffb26b;
}

.editable-input--accent-5-d .editable-input--field--icon {
  border-bottom-color: #ff5632;
}

.editable-input--success .editable-input--field--icon {
  border-bottom-color: #128c74;
}

.editable-input--success-l .editable-input--field--icon {
  border-bottom-color: #ddefea;
}

.editable-input--success-d .editable-input--field--icon {
  border-bottom-color: #0c5f4f;
}

.editable-input--warning .editable-input--field--icon {
  border-bottom-color: #ff8867;
}

.editable-input--warning-d .editable-input--field--icon {
  border-bottom-color: #e3401b;
}

.editable-input--warning-l .editable-input--field--icon {
  border-bottom-color: #ffb190;
}

.editable-input--error .editable-input--field--icon {
  border-bottom-color: #e52626;
}

.editable-input--error-d .editable-input--field--icon {
  border-bottom-color: #c70808;
}

.editable-input--error-l .editable-input--field--icon {
  border-bottom-color: #ff7676;
}

.editable-input--inherit .editable-input--field--icon {
  border-bottom-color: inherit;
}

.editable-input--white .editable-input--field--icon:hover {
  color: #fff;
}

.editable-input--facebook .editable-input--field--icon:hover {
  color: #4267b2;
}

.editable-input--twitter .editable-input--field--icon:hover {
  color: #1da1f2;
}

.editable-input--whatsapp .editable-input--field--icon:hover {
  color: #25d366;
}

.editable-input--primary-1 .editable-input--field--icon:hover {
  color: #0c806b;
}

.editable-input--primary-1-l .editable-input--field--icon:hover {
  color: #d2f8d1;
}

.editable-input--primary-1-d .editable-input--field--icon:hover {
  color: #006855;
}

.editable-input--primary-1-20 .editable-input--field--icon:hover {
  color: rgba(12, 128, 107, 0.2);
}

.editable-input--primary-1-30 .editable-input--field--icon:hover {
  color: rgba(12, 128, 107, 0.3);
}

.editable-input--primary-1-40 .editable-input--field--icon:hover {
  color: rgba(12, 128, 107, 0.4);
}

.editable-input--primary-1-60 .editable-input--field--icon:hover {
  color: rgba(12, 128, 107, 0.6);
}

.editable-input--primary-1-80 .editable-input--field--icon:hover {
  color: rgba(12, 128, 107, 0.8);
}

.editable-input--primary-2 .editable-input--field--icon:hover {
  color: #8acab7;
}

.editable-input--primary-2-l .editable-input--field--icon:hover {
  color: #e8f4f1;
}

.editable-input--primary-2-d .editable-input--field--icon:hover {
  color: #42b491;
}

.editable-input--neutral-1 .editable-input--field--icon:hover {
  color: #050505;
}

.editable-input--neutral-1-l .editable-input--field--icon:hover {
  color: #f5f5f5;
}

.editable-input--neutral-1-d .editable-input--field--icon:hover {
  color: #000000;
}

.editable-input--neutral-1-20 .editable-input--field--icon:hover {
  color: rgba(5, 5, 5, 0.2);
}

.editable-input--neutral-1-30 .editable-input--field--icon:hover {
  color: rgba(5, 5, 5, 0.3);
}

.editable-input--neutral-1-40 .editable-input--field--icon:hover {
  color: rgba(5, 5, 5, 0.4);
}

.editable-input--neutral-1-60 .editable-input--field--icon:hover {
  color: rgba(5, 5, 5, 0.6);
}

.editable-input--neutral-1-80 .editable-input--field--icon:hover {
  color: rgba(5, 5, 5, 0.8);
}

.editable-input--accent-1 .editable-input--field--icon:hover {
  color: #fc5c63;
}

.editable-input--accent-2 .editable-input--field--icon:hover {
  color: #fc847c;
}

.editable-input--accent-3 .editable-input--field--icon:hover {
  color: #ffaa98;
}

.editable-input--accent-4 .editable-input--field--icon:hover {
  color: #ffe7d1;
}

.editable-input--accent-4-20 .editable-input--field--icon:hover {
  color: rgba(255, 231, 209, 0.2);
}

.editable-input--accent-4-30 .editable-input--field--icon:hover {
  color: rgba(255, 231, 209, 0.3);
}

.editable-input--accent-4-40 .editable-input--field--icon:hover {
  color: rgba(255, 231, 209, 0.4);
}

.editable-input--accent-4-60 .editable-input--field--icon:hover {
  color: rgba(255, 231, 209, 0.6);
}

.editable-input--accent-4-80 .editable-input--field--icon:hover {
  color: rgba(255, 231, 209, 0.8);
}

.editable-input--accent-5 .editable-input--field--icon:hover {
  color: #ffaa98;
}

.editable-input--accent-1-l .editable-input--field--icon:hover {
  color: #fff2f3;
}

.editable-input--accent-2-l .editable-input--field--icon:hover {
  color: #fdb3ae;
}

.editable-input--accent-3-l .editable-input--field--icon:hover {
  color: #ffd4cb;
}

.editable-input--accent-4-l .editable-input--field--icon:hover {
  color: #fff4eb;
}

.editable-input--accent-5-l .editable-input--field--icon:hover {
  color: #ffbfb2;
}

.editable-input--accent-1-d .editable-input--field--icon:hover {
  color: #ee040f;
}

.editable-input--accent-2-d .editable-input--field--icon:hover {
  color: #fa2618;
}

.editable-input--accent-3-d .editable-input--field--icon:hover {
  color: #ff5632;
}

.editable-input--accent-4-d .editable-input--field--icon:hover {
  color: #ffb26b;
}

.editable-input--accent-5-d .editable-input--field--icon:hover {
  color: #ff5632;
}

.editable-input--success .editable-input--field--icon:hover {
  color: #128c74;
}

.editable-input--success-l .editable-input--field--icon:hover {
  color: #ddefea;
}

.editable-input--success-d .editable-input--field--icon:hover {
  color: #0c5f4f;
}

.editable-input--warning .editable-input--field--icon:hover {
  color: #ff8867;
}

.editable-input--warning-d .editable-input--field--icon:hover {
  color: #e3401b;
}

.editable-input--warning-l .editable-input--field--icon:hover {
  color: #ffb190;
}

.editable-input--error .editable-input--field--icon:hover {
  color: #e52626;
}

.editable-input--error-d .editable-input--field--icon:hover {
  color: #c70808;
}

.editable-input--error-l .editable-input--field--icon:hover {
  color: #ff7676;
}

.editable-input--inherit .editable-input--field--icon:hover {
  color: inherit;
}

.editable-input--field--currency,
.editable-input--field--icon {
  display: flex;
  align-items: center;
  border-bottom: 2px solid;
}

.editable-input--field {
  display: flex;
}
.editable-input--field--numberfield {
  flex-grow: 1;
}
.editable-input--field--currency {
  padding-right: 16px;
}
.editable-input--field--icon {
  cursor: pointer;
  color: rgba(5, 5, 5, 0.3);
}
