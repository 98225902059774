.not-found-page {
  margin: 0 auto;
  padding: 16px;
  max-width: 600px;
}
@media screen and (min-width: 768px) {
  .not-found-page {
    margin-top: 20vh;
    padding: 24px;
  }
}
