body {
  text-rendering: optimizeLegibility;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0;
}

strong {
  font-weight: 700;
}

i {
  font-style: italic;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
}

img [alt] {
  color: transparent;
}
