.add-sentence {
  display: flex;
  margin-bottom: 16px;
  cursor: pointer;
}
.add-sentence--icon {
  display: flex;
  flex: 0 0 32px;
  align-items: center;
  justify-content: center;
  background: #0c806b;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .add-sentence--icon {
    border-radius: 8px 0 0 8px;
  }
}
.add-sentence--content {
  flex-grow: 1;
  border-top: 1px dashed rgba(5, 5, 5, 0.2);
  border-bottom: 1px dashed rgba(5, 5, 5, 0.2);
}
@media screen and (min-width: 768px) {
  .add-sentence--content {
    border-right: 1px dashed rgba(5, 5, 5, 0.2);
    border-radius: 0 8px 8px 0;
  }
}
