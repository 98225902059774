@media screen and (min-width: 768px) {
  .directions-page--cards {
    display: flex;
  }
}

.directions-page--card {
  margin-bottom: 16px;
}
@media screen and (min-width: 768px) {
  .directions-page--card {
    margin-right: 24px;
    margin-bottom: 0;
    width: 50%;
  }
  .directions-page--card:last-child {
    margin-right: 0;
  }
}
