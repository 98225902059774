.editable-row {
  margin-bottom: 16px;
}
.editable-row--label {
  cursor: pointer;
}
.editable-row--value {
  display: inline-block;
  max-width: 160px;
}
.editable-row--value--amount .numberfield--input input {
  background: transparent;
  color: inherit;
  font: inherit;
}
.editable-row--value--currency {
  margin: 0 16px 2px 0;
  cursor: pointer;
}
.editable-row--value--icon {
  cursor: pointer;
  color: rgba(5, 5, 5, 0.3);
}
.editable-row--value--icon:hover {
  color: #0c806b;
}
