.sponsorship-share-link {
  display: flex;
  position: relative;
  align-items: center;
  border-bottom: 2px solid #0c806b;
  cursor: pointer;
  height: 48px;
}
.sponsorship-share-link:hover .sponsorship-share-link--icon {
  animation: listItemBounce 2s infinite;
}
.sponsorship-share-link--copied {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  opacity: 0;
  transition: opacity 300ms ease-out, transform 0s linear 300ms;
  transform: scale(1.06);
  z-index: 1;
}
.sponsorship-share-link--copied--visible {
  opacity: 1;
  transition: opacity 300ms ease-out, transform 200ms ease-out 0s;
  transform: scale(1);
}
.sponsorship-share-link--icon {
  margin: 0 8px;
}
.sponsorship-share-link--text {
  margin-right: 8px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes listItemBounce {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
