.sub-menu--white .sub-menu--item:after {
  background: #fff;
}

.sub-menu--facebook .sub-menu--item:after {
  background: #4267b2;
}

.sub-menu--twitter .sub-menu--item:after {
  background: #1da1f2;
}

.sub-menu--whatsapp .sub-menu--item:after {
  background: #25d366;
}

.sub-menu--primary-1 .sub-menu--item:after {
  background: #0c806b;
}

.sub-menu--primary-1-l .sub-menu--item:after {
  background: #d2f8d1;
}

.sub-menu--primary-1-d .sub-menu--item:after {
  background: #006855;
}

.sub-menu--primary-1-20 .sub-menu--item:after {
  background: rgba(12, 128, 107, 0.2);
}

.sub-menu--primary-1-30 .sub-menu--item:after {
  background: rgba(12, 128, 107, 0.3);
}

.sub-menu--primary-1-40 .sub-menu--item:after {
  background: rgba(12, 128, 107, 0.4);
}

.sub-menu--primary-1-60 .sub-menu--item:after {
  background: rgba(12, 128, 107, 0.6);
}

.sub-menu--primary-1-80 .sub-menu--item:after {
  background: rgba(12, 128, 107, 0.8);
}

.sub-menu--primary-2 .sub-menu--item:after {
  background: #8acab7;
}

.sub-menu--primary-2-l .sub-menu--item:after {
  background: #e8f4f1;
}

.sub-menu--primary-2-d .sub-menu--item:after {
  background: #42b491;
}

.sub-menu--neutral-1 .sub-menu--item:after {
  background: #050505;
}

.sub-menu--neutral-1-l .sub-menu--item:after {
  background: #f5f5f5;
}

.sub-menu--neutral-1-d .sub-menu--item:after {
  background: #000000;
}

.sub-menu--neutral-1-20 .sub-menu--item:after {
  background: rgba(5, 5, 5, 0.2);
}

.sub-menu--neutral-1-30 .sub-menu--item:after {
  background: rgba(5, 5, 5, 0.3);
}

.sub-menu--neutral-1-40 .sub-menu--item:after {
  background: rgba(5, 5, 5, 0.4);
}

.sub-menu--neutral-1-60 .sub-menu--item:after {
  background: rgba(5, 5, 5, 0.6);
}

.sub-menu--neutral-1-80 .sub-menu--item:after {
  background: rgba(5, 5, 5, 0.8);
}

.sub-menu--accent-1 .sub-menu--item:after {
  background: #fc5c63;
}

.sub-menu--accent-2 .sub-menu--item:after {
  background: #fc847c;
}

.sub-menu--accent-3 .sub-menu--item:after {
  background: #ffaa98;
}

.sub-menu--accent-4 .sub-menu--item:after {
  background: #ffe7d1;
}

.sub-menu--accent-4-20 .sub-menu--item:after {
  background: rgba(255, 231, 209, 0.2);
}

.sub-menu--accent-4-30 .sub-menu--item:after {
  background: rgba(255, 231, 209, 0.3);
}

.sub-menu--accent-4-40 .sub-menu--item:after {
  background: rgba(255, 231, 209, 0.4);
}

.sub-menu--accent-4-60 .sub-menu--item:after {
  background: rgba(255, 231, 209, 0.6);
}

.sub-menu--accent-4-80 .sub-menu--item:after {
  background: rgba(255, 231, 209, 0.8);
}

.sub-menu--accent-5 .sub-menu--item:after {
  background: #ffaa98;
}

.sub-menu--accent-1-l .sub-menu--item:after {
  background: #fff2f3;
}

.sub-menu--accent-2-l .sub-menu--item:after {
  background: #fdb3ae;
}

.sub-menu--accent-3-l .sub-menu--item:after {
  background: #ffd4cb;
}

.sub-menu--accent-4-l .sub-menu--item:after {
  background: #fff4eb;
}

.sub-menu--accent-5-l .sub-menu--item:after {
  background: #ffbfb2;
}

.sub-menu--accent-1-d .sub-menu--item:after {
  background: #ee040f;
}

.sub-menu--accent-2-d .sub-menu--item:after {
  background: #fa2618;
}

.sub-menu--accent-3-d .sub-menu--item:after {
  background: #ff5632;
}

.sub-menu--accent-4-d .sub-menu--item:after {
  background: #ffb26b;
}

.sub-menu--accent-5-d .sub-menu--item:after {
  background: #ff5632;
}

.sub-menu--success .sub-menu--item:after {
  background: #128c74;
}

.sub-menu--success-l .sub-menu--item:after {
  background: #ddefea;
}

.sub-menu--success-d .sub-menu--item:after {
  background: #0c5f4f;
}

.sub-menu--warning .sub-menu--item:after {
  background: #ff8867;
}

.sub-menu--warning-d .sub-menu--item:after {
  background: #e3401b;
}

.sub-menu--warning-l .sub-menu--item:after {
  background: #ffb190;
}

.sub-menu--error .sub-menu--item:after {
  background: #e52626;
}

.sub-menu--error-d .sub-menu--item:after {
  background: #c70808;
}

.sub-menu--error-l .sub-menu--item:after {
  background: #ff7676;
}

.sub-menu--inherit .sub-menu--item:after {
  background: inherit;
}

.sub-menu--white .sub-menu--item:hover {
  color: #fff;
}

.sub-menu--facebook .sub-menu--item:hover {
  color: #4267b2;
}

.sub-menu--twitter .sub-menu--item:hover {
  color: #1da1f2;
}

.sub-menu--whatsapp .sub-menu--item:hover {
  color: #25d366;
}

.sub-menu--primary-1 .sub-menu--item:hover {
  color: #0c806b;
}

.sub-menu--primary-1-l .sub-menu--item:hover {
  color: #d2f8d1;
}

.sub-menu--primary-1-d .sub-menu--item:hover {
  color: #006855;
}

.sub-menu--primary-1-20 .sub-menu--item:hover {
  color: rgba(12, 128, 107, 0.2);
}

.sub-menu--primary-1-30 .sub-menu--item:hover {
  color: rgba(12, 128, 107, 0.3);
}

.sub-menu--primary-1-40 .sub-menu--item:hover {
  color: rgba(12, 128, 107, 0.4);
}

.sub-menu--primary-1-60 .sub-menu--item:hover {
  color: rgba(12, 128, 107, 0.6);
}

.sub-menu--primary-1-80 .sub-menu--item:hover {
  color: rgba(12, 128, 107, 0.8);
}

.sub-menu--primary-2 .sub-menu--item:hover {
  color: #8acab7;
}

.sub-menu--primary-2-l .sub-menu--item:hover {
  color: #e8f4f1;
}

.sub-menu--primary-2-d .sub-menu--item:hover {
  color: #42b491;
}

.sub-menu--neutral-1 .sub-menu--item:hover {
  color: #050505;
}

.sub-menu--neutral-1-l .sub-menu--item:hover {
  color: #f5f5f5;
}

.sub-menu--neutral-1-d .sub-menu--item:hover {
  color: #000000;
}

.sub-menu--neutral-1-20 .sub-menu--item:hover {
  color: rgba(5, 5, 5, 0.2);
}

.sub-menu--neutral-1-30 .sub-menu--item:hover {
  color: rgba(5, 5, 5, 0.3);
}

.sub-menu--neutral-1-40 .sub-menu--item:hover {
  color: rgba(5, 5, 5, 0.4);
}

.sub-menu--neutral-1-60 .sub-menu--item:hover {
  color: rgba(5, 5, 5, 0.6);
}

.sub-menu--neutral-1-80 .sub-menu--item:hover {
  color: rgba(5, 5, 5, 0.8);
}

.sub-menu--accent-1 .sub-menu--item:hover {
  color: #fc5c63;
}

.sub-menu--accent-2 .sub-menu--item:hover {
  color: #fc847c;
}

.sub-menu--accent-3 .sub-menu--item:hover {
  color: #ffaa98;
}

.sub-menu--accent-4 .sub-menu--item:hover {
  color: #ffe7d1;
}

.sub-menu--accent-4-20 .sub-menu--item:hover {
  color: rgba(255, 231, 209, 0.2);
}

.sub-menu--accent-4-30 .sub-menu--item:hover {
  color: rgba(255, 231, 209, 0.3);
}

.sub-menu--accent-4-40 .sub-menu--item:hover {
  color: rgba(255, 231, 209, 0.4);
}

.sub-menu--accent-4-60 .sub-menu--item:hover {
  color: rgba(255, 231, 209, 0.6);
}

.sub-menu--accent-4-80 .sub-menu--item:hover {
  color: rgba(255, 231, 209, 0.8);
}

.sub-menu--accent-5 .sub-menu--item:hover {
  color: #ffaa98;
}

.sub-menu--accent-1-l .sub-menu--item:hover {
  color: #fff2f3;
}

.sub-menu--accent-2-l .sub-menu--item:hover {
  color: #fdb3ae;
}

.sub-menu--accent-3-l .sub-menu--item:hover {
  color: #ffd4cb;
}

.sub-menu--accent-4-l .sub-menu--item:hover {
  color: #fff4eb;
}

.sub-menu--accent-5-l .sub-menu--item:hover {
  color: #ffbfb2;
}

.sub-menu--accent-1-d .sub-menu--item:hover {
  color: #ee040f;
}

.sub-menu--accent-2-d .sub-menu--item:hover {
  color: #fa2618;
}

.sub-menu--accent-3-d .sub-menu--item:hover {
  color: #ff5632;
}

.sub-menu--accent-4-d .sub-menu--item:hover {
  color: #ffb26b;
}

.sub-menu--accent-5-d .sub-menu--item:hover {
  color: #ff5632;
}

.sub-menu--success .sub-menu--item:hover {
  color: #128c74;
}

.sub-menu--success-l .sub-menu--item:hover {
  color: #ddefea;
}

.sub-menu--success-d .sub-menu--item:hover {
  color: #0c5f4f;
}

.sub-menu--warning .sub-menu--item:hover {
  color: #ff8867;
}

.sub-menu--warning-d .sub-menu--item:hover {
  color: #e3401b;
}

.sub-menu--warning-l .sub-menu--item:hover {
  color: #ffb190;
}

.sub-menu--error .sub-menu--item:hover {
  color: #e52626;
}

.sub-menu--error-d .sub-menu--item:hover {
  color: #c70808;
}

.sub-menu--error-l .sub-menu--item:hover {
  color: #ff7676;
}

.sub-menu--inherit .sub-menu--item:hover {
  color: inherit;
}

.sub-menu--white .sub-menu--item--active {
  color: #fff;
}

.sub-menu--facebook .sub-menu--item--active {
  color: #4267b2;
}

.sub-menu--twitter .sub-menu--item--active {
  color: #1da1f2;
}

.sub-menu--whatsapp .sub-menu--item--active {
  color: #25d366;
}

.sub-menu--primary-1 .sub-menu--item--active {
  color: #0c806b;
}

.sub-menu--primary-1-l .sub-menu--item--active {
  color: #d2f8d1;
}

.sub-menu--primary-1-d .sub-menu--item--active {
  color: #006855;
}

.sub-menu--primary-1-20 .sub-menu--item--active {
  color: rgba(12, 128, 107, 0.2);
}

.sub-menu--primary-1-30 .sub-menu--item--active {
  color: rgba(12, 128, 107, 0.3);
}

.sub-menu--primary-1-40 .sub-menu--item--active {
  color: rgba(12, 128, 107, 0.4);
}

.sub-menu--primary-1-60 .sub-menu--item--active {
  color: rgba(12, 128, 107, 0.6);
}

.sub-menu--primary-1-80 .sub-menu--item--active {
  color: rgba(12, 128, 107, 0.8);
}

.sub-menu--primary-2 .sub-menu--item--active {
  color: #8acab7;
}

.sub-menu--primary-2-l .sub-menu--item--active {
  color: #e8f4f1;
}

.sub-menu--primary-2-d .sub-menu--item--active {
  color: #42b491;
}

.sub-menu--neutral-1 .sub-menu--item--active {
  color: #050505;
}

.sub-menu--neutral-1-l .sub-menu--item--active {
  color: #f5f5f5;
}

.sub-menu--neutral-1-d .sub-menu--item--active {
  color: #000000;
}

.sub-menu--neutral-1-20 .sub-menu--item--active {
  color: rgba(5, 5, 5, 0.2);
}

.sub-menu--neutral-1-30 .sub-menu--item--active {
  color: rgba(5, 5, 5, 0.3);
}

.sub-menu--neutral-1-40 .sub-menu--item--active {
  color: rgba(5, 5, 5, 0.4);
}

.sub-menu--neutral-1-60 .sub-menu--item--active {
  color: rgba(5, 5, 5, 0.6);
}

.sub-menu--neutral-1-80 .sub-menu--item--active {
  color: rgba(5, 5, 5, 0.8);
}

.sub-menu--accent-1 .sub-menu--item--active {
  color: #fc5c63;
}

.sub-menu--accent-2 .sub-menu--item--active {
  color: #fc847c;
}

.sub-menu--accent-3 .sub-menu--item--active {
  color: #ffaa98;
}

.sub-menu--accent-4 .sub-menu--item--active {
  color: #ffe7d1;
}

.sub-menu--accent-4-20 .sub-menu--item--active {
  color: rgba(255, 231, 209, 0.2);
}

.sub-menu--accent-4-30 .sub-menu--item--active {
  color: rgba(255, 231, 209, 0.3);
}

.sub-menu--accent-4-40 .sub-menu--item--active {
  color: rgba(255, 231, 209, 0.4);
}

.sub-menu--accent-4-60 .sub-menu--item--active {
  color: rgba(255, 231, 209, 0.6);
}

.sub-menu--accent-4-80 .sub-menu--item--active {
  color: rgba(255, 231, 209, 0.8);
}

.sub-menu--accent-5 .sub-menu--item--active {
  color: #ffaa98;
}

.sub-menu--accent-1-l .sub-menu--item--active {
  color: #fff2f3;
}

.sub-menu--accent-2-l .sub-menu--item--active {
  color: #fdb3ae;
}

.sub-menu--accent-3-l .sub-menu--item--active {
  color: #ffd4cb;
}

.sub-menu--accent-4-l .sub-menu--item--active {
  color: #fff4eb;
}

.sub-menu--accent-5-l .sub-menu--item--active {
  color: #ffbfb2;
}

.sub-menu--accent-1-d .sub-menu--item--active {
  color: #ee040f;
}

.sub-menu--accent-2-d .sub-menu--item--active {
  color: #fa2618;
}

.sub-menu--accent-3-d .sub-menu--item--active {
  color: #ff5632;
}

.sub-menu--accent-4-d .sub-menu--item--active {
  color: #ffb26b;
}

.sub-menu--accent-5-d .sub-menu--item--active {
  color: #ff5632;
}

.sub-menu--success .sub-menu--item--active {
  color: #128c74;
}

.sub-menu--success-l .sub-menu--item--active {
  color: #ddefea;
}

.sub-menu--success-d .sub-menu--item--active {
  color: #0c5f4f;
}

.sub-menu--warning .sub-menu--item--active {
  color: #ff8867;
}

.sub-menu--warning-d .sub-menu--item--active {
  color: #e3401b;
}

.sub-menu--warning-l .sub-menu--item--active {
  color: #ffb190;
}

.sub-menu--error .sub-menu--item--active {
  color: #e52626;
}

.sub-menu--error-d .sub-menu--item--active {
  color: #c70808;
}

.sub-menu--error-l .sub-menu--item--active {
  color: #ff7676;
}

.sub-menu--inherit .sub-menu--item--active {
  color: inherit;
}

.sub-menu {
  display: flex;
  align-items: stretch;
  margin: auto;
  height: 64px;
}
.sub-menu--wrapper {
  border-bottom: 2px solid rgba(5, 5, 5, 0.2);
}
.sub-menu--item {
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  cursor: pointer;
  padding: 0 8px;
}
@media screen and (min-width: 768px) {
  .sub-menu--item {
    flex-grow: initial;
  }
}
.sub-menu--item:last-child {
  margin-right: 0;
}
.sub-menu--item:after {
  position: absolute;
  right: 0;
  bottom: -2px;
  left: 0;
  transform: scaleX(0);
  transition: all 200ms linear;
  opacity: 0;
  border-radius: 2px;
  height: 2px;
  content: '';
}
.sub-menu--item--active:after,
.sub-menu--item:hover:after {
  transform: scaleX(1);
  opacity: 1;
}
.sub-menu--align-left {
  justify-content: flex-start;
}
.sub-menu--align-right {
  justify-content: flex-end;
}
.sub-menu--full .sub-menu--item {
  flex-grow: 1;
  justify-content: center;
}
