.sponsorship-social-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  width: 56px;
  height: 56px;
  font-size: 24px;
}
.sponsorship-social-button--facebook {
  background: #4267b2;
}
.sponsorship-social-button--twitter {
  background: #1da1f2;
}
.sponsorship-social-button--linkedin {
  background: #3b5998;
}
.sponsorship-social-button--whatsapp {
  background: #25d366;
}
.sponsorship-social-button--small {
  width: 32px;
  height: 32px;
  font-size: 16px;
}
.sponsorship-social-button--medium {
  width: 56px;
  height: 56px;
  font-size: 24px;
}
