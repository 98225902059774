.autosize--input:focus-within {
  outline: 1px dashed #0c806b;
}

.autosize--input {
  display: inline;
  position: relative;
  border-bottom: 2px dashed #0c806b;
  border-radius: 0;
  padding: 4px 8px 7px;
  min-width: 8px;
  font-weight: 600;
  display: inline-block;
  padding: 0 8px;
  line-height: 32px;
  letter-spacing: normal;
}
@media screen and (min-width: 768px) {
  .autosize--input {
    display: inline-block;
    padding: 0 8px;
    height: 32px;
    line-height: 32px;
  }
}
.autosize--input input {
  border: 0;
  padding: 0;
  text-align: center;
  color: #0c806b;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
}
.autosize--input input:focus {
  outline: none;
}
.autosize--input input::placeholder {
  color: rgba(5, 5, 5, 0.3);
  font-weight: 400;
}
.autosize--input input::-webkit-input-placeholder {
  color: rgba(5, 5, 5, 0.3);
  font-weight: 400;
}
.autosize--input input:-moz-placeholder {
  color: rgba(5, 5, 5, 0.3);
  font-weight: 400;
}
.autosize--input input::-moz-placeholder {
  color: rgba(5, 5, 5, 0.3);
  font-weight: 400;
}
.autosize--input input:-ms-input-placeholder {
  color: rgba(5, 5, 5, 0.3);
  font-weight: 400;
}
.autosize--input-is-valid {
  border-style: solid;
}
.autosize--input-error {
  border-color: #e52626;
}
.autosize--input-error input {
  color: #e52626;
}
