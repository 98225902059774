.alert-template {
  background: #f5f5f5;
  padding-bottom: 24px;
  min-height: 100%;
}
.alert-template--header {
  background: #fff;
}
@media screen and (min-width: 768px) {
  .alert-template--header {
    padding: 0 24px;
  }
}
@media screen and (min-width: 768px) {
  .alert-template--header {
    padding: 0;
  }
}
.alert-template--header--title {
  padding: 16px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .alert-template--header--title {
    padding: 40px 0 0;
  }
}
.alert-template--header--description {
  background: #f5f5f5;
  padding: 32px 16px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .alert-template--header--description {
    background: initial;
    padding: 24px;
  }
}
.alert-template--header--alert {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  padding-bottom: 16px;
  max-width: 1240px;
}
@media screen and (min-width: 768px) {
  .alert-template--header--alert {
    margin-top: 40px;
    padding-bottom: 8px;
    padding-right: 24px;
    padding-left: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .alert-template--header--alert {
    padding-right: 0;
    padding-left: 0;
  }
}
.alert-template--wrapper {
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .alert-template--wrapper {
    padding: 24px;
  }
  .alert-template--wrapper::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: #fff;
    width: 100%;
    height: 80px;
    content: '';
  }
}
@media screen and (min-width: 1024px) {
  .alert-template--wrapper {
    padding-right: 0;
    padding-left: 0;
  }
}
