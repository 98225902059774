.button:focus {
  outline: 1px dashed #0c806b;
}

.button {
  display: inline-block;
  color: inherit;
  text-align: center;
  padding: 24px;
  border-radius: 8px;
  font-family: 'Maison Neue';
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: background 300ms ease-in-out, box-shadow 300ms ease-in-out;
  -moz-transition: background 300ms ease-in-out, box-shadow 300ms ease-in-out;
  -o-transition: background 300ms ease-in-out, box-shadow 300ms ease-in-out;
  -webkit-transition: background 300ms ease-in-out, box-shadow 300ms ease-in-out;
  /* DESCENDANTS */
  /* MODIFIERS */
}
.button--white {
  background-color: #fff;
}
.button--facebook {
  background-color: #4267b2;
}
.button--twitter {
  background-color: #1da1f2;
}
.button--whatsapp {
  background-color: #25d366;
}
.button--primary-1 {
  background-color: #0c806b;
}
.button--primary-1-l {
  background-color: #d2f8d1;
}
.button--primary-1-d {
  background-color: #006855;
}
.button--primary-1-20 {
  background-color: rgba(12, 128, 107, 0.2);
}
.button--primary-1-30 {
  background-color: rgba(12, 128, 107, 0.3);
}
.button--primary-1-40 {
  background-color: rgba(12, 128, 107, 0.4);
}
.button--primary-1-60 {
  background-color: rgba(12, 128, 107, 0.6);
}
.button--primary-1-80 {
  background-color: rgba(12, 128, 107, 0.8);
}
.button--primary-2 {
  background-color: #8acab7;
}
.button--primary-2-l {
  background-color: #e8f4f1;
}
.button--primary-2-d {
  background-color: #42b491;
}
.button--neutral-1 {
  background-color: #050505;
}
.button--neutral-1-l {
  background-color: #f5f5f5;
}
.button--neutral-1-d {
  background-color: #000000;
}
.button--neutral-1-20 {
  background-color: rgba(5, 5, 5, 0.2);
}
.button--neutral-1-30 {
  background-color: rgba(5, 5, 5, 0.3);
}
.button--neutral-1-40 {
  background-color: rgba(5, 5, 5, 0.4);
}
.button--neutral-1-60 {
  background-color: rgba(5, 5, 5, 0.6);
}
.button--neutral-1-80 {
  background-color: rgba(5, 5, 5, 0.8);
}
.button--accent-1 {
  background-color: #fc5c63;
}
.button--accent-2 {
  background-color: #fc847c;
}
.button--accent-3 {
  background-color: #ffaa98;
}
.button--accent-4 {
  background-color: #ffe7d1;
}
.button--accent-4-20 {
  background-color: rgba(255, 231, 209, 0.2);
}
.button--accent-4-30 {
  background-color: rgba(255, 231, 209, 0.3);
}
.button--accent-4-40 {
  background-color: rgba(255, 231, 209, 0.4);
}
.button--accent-4-60 {
  background-color: rgba(255, 231, 209, 0.6);
}
.button--accent-4-80 {
  background-color: rgba(255, 231, 209, 0.8);
}
.button--accent-5 {
  background-color: #ffaa98;
}
.button--accent-1-l {
  background-color: #fff2f3;
}
.button--accent-2-l {
  background-color: #fdb3ae;
}
.button--accent-3-l {
  background-color: #ffd4cb;
}
.button--accent-4-l {
  background-color: #fff4eb;
}
.button--accent-5-l {
  background-color: #ffbfb2;
}
.button--accent-1-d {
  background-color: #ee040f;
}
.button--accent-2-d {
  background-color: #fa2618;
}
.button--accent-3-d {
  background-color: #ff5632;
}
.button--accent-4-d {
  background-color: #ffb26b;
}
.button--accent-5-d {
  background-color: #ff5632;
}
.button--success {
  background-color: #128c74;
}
.button--success-l {
  background-color: #ddefea;
}
.button--success-d {
  background-color: #0c5f4f;
}
.button--warning {
  background-color: #ff8867;
}
.button--warning-d {
  background-color: #e3401b;
}
.button--warning-l {
  background-color: #ffb190;
}
.button--error {
  background-color: #e52626;
}
.button--error-d {
  background-color: #c70808;
}
.button--error-l {
  background-color: #ff7676;
}
.button--inherit {
  background-color: inherit;
}
.button:hover.button {
  box-shadow: 0 2px 10px 0 rgba(5, 5, 5, 0.2);
}
.button:hover.button--white {
  background-color: #050505;
}
.button:hover.button--facebook {
  background-color: #4267b2;
}
.button:hover.button--twitter {
  background-color: #1da1f2;
}
.button:hover.button--whatsapp {
  background-color: #25d366;
}
.button:hover.button--primary-1 {
  background-color: #006855;
}
.button:hover.button--primary-1-l {
  background-color: #0c806b;
}
.button:hover.button--primary-1-d {
  background-color: #006855;
}
.button:hover.button--primary-1-20 {
  background-color: #0c806b;
}
.button:hover.button--primary-1-30 {
  background-color: #0c806b;
}
.button:hover.button--primary-1-40 {
  background-color: #0c806b;
}
.button:hover.button--primary-1-60 {
  background-color: #0c806b;
}
.button:hover.button--primary-1-80 {
  background-color: #0c806b;
}
.button:hover.button--primary-2 {
  background-color: #42b491;
}
.button:hover.button--primary-2-l {
  background-color: #8acab7;
}
.button:hover.button--primary-2-d {
  background-color: #348f73;
}
.button:hover.button--neutral-1 {
  background-color: #000000;
}
.button:hover.button--neutral-1-l {
  background-color: rgba(5, 5, 5, 0.2);
}
.button:hover.button--neutral-1-d {
  background-color: #000000;
}
.button:hover.button--neutral-1-20 {
  background-color: #050505;
}
.button:hover.button--neutral-1-30 {
  background-color: #050505;
}
.button:hover.button--neutral-1-40 {
  background-color: #050505;
}
.button:hover.button--neutral-1-60 {
  background-color: #050505;
}
.button:hover.button--neutral-1-80 {
  background-color: #050505;
}
.button:hover.button--accent-1 {
  background-color: #ee040f;
}
.button:hover.button--accent-2 {
  background-color: #fa2618;
}
.button:hover.button--accent-3 {
  background-color: #ff5632;
}
.button:hover.button--accent-4 {
  background-color: #ffb26b;
}
.button:hover.button--accent-4-20 {
  background-color: #ffe7d1;
}
.button:hover.button--accent-4-30 {
  background-color: #ffe7d1;
}
.button:hover.button--accent-4-40 {
  background-color: #ffe7d1;
}
.button:hover.button--accent-4-60 {
  background-color: #ffe7d1;
}
.button:hover.button--accent-4-80 {
  background-color: #ffe7d1;
}
.button:hover.button--accent-5 {
  background-color: #ff5632;
}
.button:hover.button--accent-1-l {
  background-color: #fc5c63;
}
.button:hover.button--accent-2-l {
  background-color: #fc847c;
}
.button:hover.button--accent-3-l {
  background-color: #ffaa98;
}
.button:hover.button--accent-4-l {
  background-color: #ffe7d1;
}
.button:hover.button--accent-5-l {
  background-color: #ffaa98;
}
.button:hover.button--accent-1-d {
  background-color: #ee040f;
}
.button:hover.button--accent-2-d {
  background-color: #fa2618;
}
.button:hover.button--accent-3-d {
  background-color: #ff5632;
}
.button:hover.button--accent-4-d {
  background-color: #ffb26b;
}
.button:hover.button--accent-5-d {
  background-color: #ff5632;
}
.button:hover.button--success {
  background-color: #0c5f4f;
}
.button:hover.button--success-l {
  background-color: #128c74;
}
.button:hover.button--success-d {
  background-color: #063229;
}
.button:hover.button--warning {
  background-color: #e3401b;
}
.button:hover.button--warning-d {
  background-color: #e3401b;
}
.button:hover.button--warning-l {
  background-color: #ff8867;
}
.button:hover.button--error {
  background-color: #c70808;
}
.button:hover.button--error-d {
  background-color: #c70808;
}
.button:hover.button--error-l {
  background-color: #e52626;
}
.button:hover.button--inherit {
  background-color: inherit;
}
@media screen and (max-width: 425px) {
  .button {
    width: 100%;
  }
}
.button__icon--left {
  padding-right: 16px;
}
.button__icon--right {
  padding-left: 16px;
}
.button__icon--small {
  font-size: 8px;
}
.button__icon--medium {
  font-size: 16px;
}
.button__icon--large {
  font-size: 24px;
}
.button__icon-label {
  display: inline-block;
}
.button__text {
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
}
.button__text--white {
  color: #fff;
}
.button__text--facebook {
  color: #4267b2;
}
.button__text--twitter {
  color: #1da1f2;
}
.button__text--whatsapp {
  color: #25d366;
}
.button__text--primary-1 {
  color: #0c806b;
}
.button__text--primary-1-l {
  color: #d2f8d1;
}
.button__text--primary-1-d {
  color: #006855;
}
.button__text--primary-1-20 {
  color: rgba(12, 128, 107, 0.2);
}
.button__text--primary-1-30 {
  color: rgba(12, 128, 107, 0.3);
}
.button__text--primary-1-40 {
  color: rgba(12, 128, 107, 0.4);
}
.button__text--primary-1-60 {
  color: rgba(12, 128, 107, 0.6);
}
.button__text--primary-1-80 {
  color: rgba(12, 128, 107, 0.8);
}
.button__text--primary-2 {
  color: #8acab7;
}
.button__text--primary-2-l {
  color: #e8f4f1;
}
.button__text--primary-2-d {
  color: #42b491;
}
.button__text--neutral-1 {
  color: #050505;
}
.button__text--neutral-1-l {
  color: #f5f5f5;
}
.button__text--neutral-1-d {
  color: #000000;
}
.button__text--neutral-1-20 {
  color: rgba(5, 5, 5, 0.2);
}
.button__text--neutral-1-30 {
  color: rgba(5, 5, 5, 0.3);
}
.button__text--neutral-1-40 {
  color: rgba(5, 5, 5, 0.4);
}
.button__text--neutral-1-60 {
  color: rgba(5, 5, 5, 0.6);
}
.button__text--neutral-1-80 {
  color: rgba(5, 5, 5, 0.8);
}
.button__text--accent-1 {
  color: #fc5c63;
}
.button__text--accent-2 {
  color: #fc847c;
}
.button__text--accent-3 {
  color: #ffaa98;
}
.button__text--accent-4 {
  color: #ffe7d1;
}
.button__text--accent-4-20 {
  color: rgba(255, 231, 209, 0.2);
}
.button__text--accent-4-30 {
  color: rgba(255, 231, 209, 0.3);
}
.button__text--accent-4-40 {
  color: rgba(255, 231, 209, 0.4);
}
.button__text--accent-4-60 {
  color: rgba(255, 231, 209, 0.6);
}
.button__text--accent-4-80 {
  color: rgba(255, 231, 209, 0.8);
}
.button__text--accent-5 {
  color: #ffaa98;
}
.button__text--accent-1-l {
  color: #fff2f3;
}
.button__text--accent-2-l {
  color: #fdb3ae;
}
.button__text--accent-3-l {
  color: #ffd4cb;
}
.button__text--accent-4-l {
  color: #fff4eb;
}
.button__text--accent-5-l {
  color: #ffbfb2;
}
.button__text--accent-1-d {
  color: #ee040f;
}
.button__text--accent-2-d {
  color: #fa2618;
}
.button__text--accent-3-d {
  color: #ff5632;
}
.button__text--accent-4-d {
  color: #ffb26b;
}
.button__text--accent-5-d {
  color: #ff5632;
}
.button__text--success {
  color: #128c74;
}
.button__text--success-l {
  color: #ddefea;
}
.button__text--success-d {
  color: #0c5f4f;
}
.button__text--warning {
  color: #ff8867;
}
.button__text--warning-d {
  color: #e3401b;
}
.button__text--warning-l {
  color: #ffb190;
}
.button__text--error {
  color: #e52626;
}
.button__text--error-d {
  color: #c70808;
}
.button__text--error-l {
  color: #ff7676;
}
.button__text--inherit {
  color: inherit;
}
.button--circle {
  border-radius: 100%;
  display: block;
  font-size: 16px;
  padding: 0;
  position: relative;
}
.button--circle.button--x-small {
  width: 40px;
  height: 40px;
  font-size: 12px;
}
.button--circle.button--small {
  width: 48px;
  height: 48px;
}
.button--circle.button--medium {
  width: 56px;
  height: 56px;
}
.button--circle.button--large {
  width: 64px;
  height: 64px;
}
.button--circle .icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.button--disabled {
  cursor: not-allowed;
  position: relative;
}
.button--disabled:after {
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  opacity: 0.8;
  border-radius: inherit;
  background: #fff;
  content: '';
}
.button--disabled:hover.button {
  box-shadow: none;
}
.button--disabled:hover.button--white {
  background-color: #fff;
}
.button--disabled:hover.button--facebook {
  background-color: #4267b2;
}
.button--disabled:hover.button--twitter {
  background-color: #1da1f2;
}
.button--disabled:hover.button--whatsapp {
  background-color: #25d366;
}
.button--disabled:hover.button--primary-1 {
  background-color: #0c806b;
}
.button--disabled:hover.button--primary-1-l {
  background-color: #d2f8d1;
}
.button--disabled:hover.button--primary-1-d {
  background-color: #006855;
}
.button--disabled:hover.button--primary-1-20 {
  background-color: rgba(12, 128, 107, 0.2);
}
.button--disabled:hover.button--primary-1-30 {
  background-color: rgba(12, 128, 107, 0.3);
}
.button--disabled:hover.button--primary-1-40 {
  background-color: rgba(12, 128, 107, 0.4);
}
.button--disabled:hover.button--primary-1-60 {
  background-color: rgba(12, 128, 107, 0.6);
}
.button--disabled:hover.button--primary-1-80 {
  background-color: rgba(12, 128, 107, 0.8);
}
.button--disabled:hover.button--primary-2 {
  background-color: #8acab7;
}
.button--disabled:hover.button--primary-2-l {
  background-color: #e8f4f1;
}
.button--disabled:hover.button--primary-2-d {
  background-color: #42b491;
}
.button--disabled:hover.button--neutral-1 {
  background-color: #050505;
}
.button--disabled:hover.button--neutral-1-l {
  background-color: #f5f5f5;
}
.button--disabled:hover.button--neutral-1-d {
  background-color: #000000;
}
.button--disabled:hover.button--neutral-1-20 {
  background-color: rgba(5, 5, 5, 0.2);
}
.button--disabled:hover.button--neutral-1-30 {
  background-color: rgba(5, 5, 5, 0.3);
}
.button--disabled:hover.button--neutral-1-40 {
  background-color: rgba(5, 5, 5, 0.4);
}
.button--disabled:hover.button--neutral-1-60 {
  background-color: rgba(5, 5, 5, 0.6);
}
.button--disabled:hover.button--neutral-1-80 {
  background-color: rgba(5, 5, 5, 0.8);
}
.button--disabled:hover.button--accent-1 {
  background-color: #fc5c63;
}
.button--disabled:hover.button--accent-2 {
  background-color: #fc847c;
}
.button--disabled:hover.button--accent-3 {
  background-color: #ffaa98;
}
.button--disabled:hover.button--accent-4 {
  background-color: #ffe7d1;
}
.button--disabled:hover.button--accent-4-20 {
  background-color: rgba(255, 231, 209, 0.2);
}
.button--disabled:hover.button--accent-4-30 {
  background-color: rgba(255, 231, 209, 0.3);
}
.button--disabled:hover.button--accent-4-40 {
  background-color: rgba(255, 231, 209, 0.4);
}
.button--disabled:hover.button--accent-4-60 {
  background-color: rgba(255, 231, 209, 0.6);
}
.button--disabled:hover.button--accent-4-80 {
  background-color: rgba(255, 231, 209, 0.8);
}
.button--disabled:hover.button--accent-5 {
  background-color: #ffaa98;
}
.button--disabled:hover.button--accent-1-l {
  background-color: #fff2f3;
}
.button--disabled:hover.button--accent-2-l {
  background-color: #fdb3ae;
}
.button--disabled:hover.button--accent-3-l {
  background-color: #ffd4cb;
}
.button--disabled:hover.button--accent-4-l {
  background-color: #fff4eb;
}
.button--disabled:hover.button--accent-5-l {
  background-color: #ffbfb2;
}
.button--disabled:hover.button--accent-1-d {
  background-color: #ee040f;
}
.button--disabled:hover.button--accent-2-d {
  background-color: #fa2618;
}
.button--disabled:hover.button--accent-3-d {
  background-color: #ff5632;
}
.button--disabled:hover.button--accent-4-d {
  background-color: #ffb26b;
}
.button--disabled:hover.button--accent-5-d {
  background-color: #ff5632;
}
.button--disabled:hover.button--success {
  background-color: #128c74;
}
.button--disabled:hover.button--success-l {
  background-color: #ddefea;
}
.button--disabled:hover.button--success-d {
  background-color: #0c5f4f;
}
.button--disabled:hover.button--warning {
  background-color: #ff8867;
}
.button--disabled:hover.button--warning-d {
  background-color: #e3401b;
}
.button--disabled:hover.button--warning-l {
  background-color: #ffb190;
}
.button--disabled:hover.button--error {
  background-color: #e52626;
}
.button--disabled:hover.button--error-d {
  background-color: #c70808;
}
.button--disabled:hover.button--error-l {
  background-color: #ff7676;
}
.button--disabled:hover.button--inherit {
  background-color: inherit;
}
.button--disabled.button--line:hover.button--white {
  border-color: #fff;
}
.button--disabled.button--line:hover.button--facebook {
  border-color: #4267b2;
}
.button--disabled.button--line:hover.button--twitter {
  border-color: #1da1f2;
}
.button--disabled.button--line:hover.button--whatsapp {
  border-color: #25d366;
}
.button--disabled.button--line:hover.button--primary-1 {
  border-color: #0c806b;
}
.button--disabled.button--line:hover.button--primary-1-l {
  border-color: #d2f8d1;
}
.button--disabled.button--line:hover.button--primary-1-d {
  border-color: #006855;
}
.button--disabled.button--line:hover.button--primary-1-20 {
  border-color: rgba(12, 128, 107, 0.2);
}
.button--disabled.button--line:hover.button--primary-1-30 {
  border-color: rgba(12, 128, 107, 0.3);
}
.button--disabled.button--line:hover.button--primary-1-40 {
  border-color: rgba(12, 128, 107, 0.4);
}
.button--disabled.button--line:hover.button--primary-1-60 {
  border-color: rgba(12, 128, 107, 0.6);
}
.button--disabled.button--line:hover.button--primary-1-80 {
  border-color: rgba(12, 128, 107, 0.8);
}
.button--disabled.button--line:hover.button--primary-2 {
  border-color: #8acab7;
}
.button--disabled.button--line:hover.button--primary-2-l {
  border-color: #e8f4f1;
}
.button--disabled.button--line:hover.button--primary-2-d {
  border-color: #42b491;
}
.button--disabled.button--line:hover.button--neutral-1 {
  border-color: #050505;
}
.button--disabled.button--line:hover.button--neutral-1-l {
  border-color: #f5f5f5;
}
.button--disabled.button--line:hover.button--neutral-1-d {
  border-color: #000000;
}
.button--disabled.button--line:hover.button--neutral-1-20 {
  border-color: rgba(5, 5, 5, 0.2);
}
.button--disabled.button--line:hover.button--neutral-1-30 {
  border-color: rgba(5, 5, 5, 0.3);
}
.button--disabled.button--line:hover.button--neutral-1-40 {
  border-color: rgba(5, 5, 5, 0.4);
}
.button--disabled.button--line:hover.button--neutral-1-60 {
  border-color: rgba(5, 5, 5, 0.6);
}
.button--disabled.button--line:hover.button--neutral-1-80 {
  border-color: rgba(5, 5, 5, 0.8);
}
.button--disabled.button--line:hover.button--accent-1 {
  border-color: #fc5c63;
}
.button--disabled.button--line:hover.button--accent-2 {
  border-color: #fc847c;
}
.button--disabled.button--line:hover.button--accent-3 {
  border-color: #ffaa98;
}
.button--disabled.button--line:hover.button--accent-4 {
  border-color: #ffe7d1;
}
.button--disabled.button--line:hover.button--accent-4-20 {
  border-color: rgba(255, 231, 209, 0.2);
}
.button--disabled.button--line:hover.button--accent-4-30 {
  border-color: rgba(255, 231, 209, 0.3);
}
.button--disabled.button--line:hover.button--accent-4-40 {
  border-color: rgba(255, 231, 209, 0.4);
}
.button--disabled.button--line:hover.button--accent-4-60 {
  border-color: rgba(255, 231, 209, 0.6);
}
.button--disabled.button--line:hover.button--accent-4-80 {
  border-color: rgba(255, 231, 209, 0.8);
}
.button--disabled.button--line:hover.button--accent-5 {
  border-color: #ffaa98;
}
.button--disabled.button--line:hover.button--accent-1-l {
  border-color: #fff2f3;
}
.button--disabled.button--line:hover.button--accent-2-l {
  border-color: #fdb3ae;
}
.button--disabled.button--line:hover.button--accent-3-l {
  border-color: #ffd4cb;
}
.button--disabled.button--line:hover.button--accent-4-l {
  border-color: #fff4eb;
}
.button--disabled.button--line:hover.button--accent-5-l {
  border-color: #ffbfb2;
}
.button--disabled.button--line:hover.button--accent-1-d {
  border-color: #ee040f;
}
.button--disabled.button--line:hover.button--accent-2-d {
  border-color: #fa2618;
}
.button--disabled.button--line:hover.button--accent-3-d {
  border-color: #ff5632;
}
.button--disabled.button--line:hover.button--accent-4-d {
  border-color: #ffb26b;
}
.button--disabled.button--line:hover.button--accent-5-d {
  border-color: #ff5632;
}
.button--disabled.button--line:hover.button--success {
  border-color: #128c74;
}
.button--disabled.button--line:hover.button--success-l {
  border-color: #ddefea;
}
.button--disabled.button--line:hover.button--success-d {
  border-color: #0c5f4f;
}
.button--disabled.button--line:hover.button--warning {
  border-color: #ff8867;
}
.button--disabled.button--line:hover.button--warning-d {
  border-color: #e3401b;
}
.button--disabled.button--line:hover.button--warning-l {
  border-color: #ffb190;
}
.button--disabled.button--line:hover.button--error {
  border-color: #e52626;
}
.button--disabled.button--line:hover.button--error-d {
  border-color: #c70808;
}
.button--disabled.button--line:hover.button--error-l {
  border-color: #ff7676;
}
.button--disabled.button--line:hover.button--inherit {
  border-color: inherit;
}
.button--disabled.button--line:hover.button--white {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--facebook {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--twitter {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--whatsapp {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--primary-1 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--primary-1-l {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--primary-1-d {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--primary-1-20 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--primary-1-30 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--primary-1-40 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--primary-1-60 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--primary-1-80 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--primary-2 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--primary-2-l {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--primary-2-d {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--neutral-1 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--neutral-1-l {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--neutral-1-d {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--neutral-1-20 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--neutral-1-30 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--neutral-1-40 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--neutral-1-60 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--neutral-1-80 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-1 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-2 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-3 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-4 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-4-20 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-4-30 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-4-40 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-4-60 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-4-80 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-5 {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-1-l {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-2-l {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-3-l {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-4-l {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-5-l {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-1-d {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-2-d {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-3-d {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-4-d {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--accent-5-d {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--success {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--success-l {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--success-d {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--warning {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--warning-d {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--warning-l {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--error {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--error-d {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--error-l {
  background-color: #fff;
}
.button--disabled.button--line:hover.button--inherit {
  background-color: #fff;
}
.button--flat {
  font-size: 16px;
}
.button--flat .button__text {
  justify-content: left;
  transition: color 300ms linear;
  color: inherit;
}
.button--flat.button,
.button--flat.button:hover {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background: transparent;
  padding: 0;
  text-align: left;
  text-transform: initial;
  font-weight: 500;
}
.button--full {
  width: 100%;
}
.button--line.button--white {
  border-color: #fff;
}
.button--line.button--facebook {
  border-color: #4267b2;
}
.button--line.button--twitter {
  border-color: #1da1f2;
}
.button--line.button--whatsapp {
  border-color: #25d366;
}
.button--line.button--primary-1 {
  border-color: #0c806b;
}
.button--line.button--primary-1-l {
  border-color: #d2f8d1;
}
.button--line.button--primary-1-d {
  border-color: #006855;
}
.button--line.button--primary-1-20 {
  border-color: rgba(12, 128, 107, 0.2);
}
.button--line.button--primary-1-30 {
  border-color: rgba(12, 128, 107, 0.3);
}
.button--line.button--primary-1-40 {
  border-color: rgba(12, 128, 107, 0.4);
}
.button--line.button--primary-1-60 {
  border-color: rgba(12, 128, 107, 0.6);
}
.button--line.button--primary-1-80 {
  border-color: rgba(12, 128, 107, 0.8);
}
.button--line.button--primary-2 {
  border-color: #8acab7;
}
.button--line.button--primary-2-l {
  border-color: #e8f4f1;
}
.button--line.button--primary-2-d {
  border-color: #42b491;
}
.button--line.button--neutral-1 {
  border-color: #050505;
}
.button--line.button--neutral-1-l {
  border-color: #f5f5f5;
}
.button--line.button--neutral-1-d {
  border-color: #000000;
}
.button--line.button--neutral-1-20 {
  border-color: rgba(5, 5, 5, 0.2);
}
.button--line.button--neutral-1-30 {
  border-color: rgba(5, 5, 5, 0.3);
}
.button--line.button--neutral-1-40 {
  border-color: rgba(5, 5, 5, 0.4);
}
.button--line.button--neutral-1-60 {
  border-color: rgba(5, 5, 5, 0.6);
}
.button--line.button--neutral-1-80 {
  border-color: rgba(5, 5, 5, 0.8);
}
.button--line.button--accent-1 {
  border-color: #fc5c63;
}
.button--line.button--accent-2 {
  border-color: #fc847c;
}
.button--line.button--accent-3 {
  border-color: #ffaa98;
}
.button--line.button--accent-4 {
  border-color: #ffe7d1;
}
.button--line.button--accent-4-20 {
  border-color: rgba(255, 231, 209, 0.2);
}
.button--line.button--accent-4-30 {
  border-color: rgba(255, 231, 209, 0.3);
}
.button--line.button--accent-4-40 {
  border-color: rgba(255, 231, 209, 0.4);
}
.button--line.button--accent-4-60 {
  border-color: rgba(255, 231, 209, 0.6);
}
.button--line.button--accent-4-80 {
  border-color: rgba(255, 231, 209, 0.8);
}
.button--line.button--accent-5 {
  border-color: #ffaa98;
}
.button--line.button--accent-1-l {
  border-color: #fff2f3;
}
.button--line.button--accent-2-l {
  border-color: #fdb3ae;
}
.button--line.button--accent-3-l {
  border-color: #ffd4cb;
}
.button--line.button--accent-4-l {
  border-color: #fff4eb;
}
.button--line.button--accent-5-l {
  border-color: #ffbfb2;
}
.button--line.button--accent-1-d {
  border-color: #ee040f;
}
.button--line.button--accent-2-d {
  border-color: #fa2618;
}
.button--line.button--accent-3-d {
  border-color: #ff5632;
}
.button--line.button--accent-4-d {
  border-color: #ffb26b;
}
.button--line.button--accent-5-d {
  border-color: #ff5632;
}
.button--line.button--success {
  border-color: #128c74;
}
.button--line.button--success-l {
  border-color: #ddefea;
}
.button--line.button--success-d {
  border-color: #0c5f4f;
}
.button--line.button--warning {
  border-color: #ff8867;
}
.button--line.button--warning-d {
  border-color: #e3401b;
}
.button--line.button--warning-l {
  border-color: #ffb190;
}
.button--line.button--error {
  border-color: #e52626;
}
.button--line.button--error-d {
  border-color: #c70808;
}
.button--line.button--error-l {
  border-color: #ff7676;
}
.button--line.button--inherit {
  border-color: inherit;
}
.button--line.button--white {
  background-color: #fff;
}
.button--line.button--facebook {
  background-color: #fff;
}
.button--line.button--twitter {
  background-color: #fff;
}
.button--line.button--whatsapp {
  background-color: #fff;
}
.button--line.button--primary-1 {
  background-color: #fff;
}
.button--line.button--primary-1-l {
  background-color: #fff;
}
.button--line.button--primary-1-d {
  background-color: #fff;
}
.button--line.button--primary-1-20 {
  background-color: #fff;
}
.button--line.button--primary-1-30 {
  background-color: #fff;
}
.button--line.button--primary-1-40 {
  background-color: #fff;
}
.button--line.button--primary-1-60 {
  background-color: #fff;
}
.button--line.button--primary-1-80 {
  background-color: #fff;
}
.button--line.button--primary-2 {
  background-color: #fff;
}
.button--line.button--primary-2-l {
  background-color: #fff;
}
.button--line.button--primary-2-d {
  background-color: #fff;
}
.button--line.button--neutral-1 {
  background-color: #fff;
}
.button--line.button--neutral-1-l {
  background-color: #fff;
}
.button--line.button--neutral-1-d {
  background-color: #fff;
}
.button--line.button--neutral-1-20 {
  background-color: #fff;
}
.button--line.button--neutral-1-30 {
  background-color: #fff;
}
.button--line.button--neutral-1-40 {
  background-color: #fff;
}
.button--line.button--neutral-1-60 {
  background-color: #fff;
}
.button--line.button--neutral-1-80 {
  background-color: #fff;
}
.button--line.button--accent-1 {
  background-color: #fff;
}
.button--line.button--accent-2 {
  background-color: #fff;
}
.button--line.button--accent-3 {
  background-color: #fff;
}
.button--line.button--accent-4 {
  background-color: #fff;
}
.button--line.button--accent-4-20 {
  background-color: #fff;
}
.button--line.button--accent-4-30 {
  background-color: #fff;
}
.button--line.button--accent-4-40 {
  background-color: #fff;
}
.button--line.button--accent-4-60 {
  background-color: #fff;
}
.button--line.button--accent-4-80 {
  background-color: #fff;
}
.button--line.button--accent-5 {
  background-color: #fff;
}
.button--line.button--accent-1-l {
  background-color: #fff;
}
.button--line.button--accent-2-l {
  background-color: #fff;
}
.button--line.button--accent-3-l {
  background-color: #fff;
}
.button--line.button--accent-4-l {
  background-color: #fff;
}
.button--line.button--accent-5-l {
  background-color: #fff;
}
.button--line.button--accent-1-d {
  background-color: #fff;
}
.button--line.button--accent-2-d {
  background-color: #fff;
}
.button--line.button--accent-3-d {
  background-color: #fff;
}
.button--line.button--accent-4-d {
  background-color: #fff;
}
.button--line.button--accent-5-d {
  background-color: #fff;
}
.button--line.button--success {
  background-color: #fff;
}
.button--line.button--success-l {
  background-color: #fff;
}
.button--line.button--success-d {
  background-color: #fff;
}
.button--line.button--warning {
  background-color: #fff;
}
.button--line.button--warning-d {
  background-color: #fff;
}
.button--line.button--warning-l {
  background-color: #fff;
}
.button--line.button--error {
  background-color: #fff;
}
.button--line.button--error-d {
  background-color: #fff;
}
.button--line.button--error-l {
  background-color: #fff;
}
.button--line.button--inherit {
  background-color: #fff;
}
.button--line.button--white .button__text {
  color: #fff;
}
.button--line.button--facebook .button__text {
  color: #4267b2;
}
.button--line.button--twitter .button__text {
  color: #1da1f2;
}
.button--line.button--whatsapp .button__text {
  color: #25d366;
}
.button--line.button--primary-1 .button__text {
  color: #0c806b;
}
.button--line.button--primary-1-l .button__text {
  color: #d2f8d1;
}
.button--line.button--primary-1-d .button__text {
  color: #006855;
}
.button--line.button--primary-1-20 .button__text {
  color: rgba(12, 128, 107, 0.2);
}
.button--line.button--primary-1-30 .button__text {
  color: rgba(12, 128, 107, 0.3);
}
.button--line.button--primary-1-40 .button__text {
  color: rgba(12, 128, 107, 0.4);
}
.button--line.button--primary-1-60 .button__text {
  color: rgba(12, 128, 107, 0.6);
}
.button--line.button--primary-1-80 .button__text {
  color: rgba(12, 128, 107, 0.8);
}
.button--line.button--primary-2 .button__text {
  color: #8acab7;
}
.button--line.button--primary-2-l .button__text {
  color: #e8f4f1;
}
.button--line.button--primary-2-d .button__text {
  color: #42b491;
}
.button--line.button--neutral-1 .button__text {
  color: #050505;
}
.button--line.button--neutral-1-l .button__text {
  color: #f5f5f5;
}
.button--line.button--neutral-1-d .button__text {
  color: #000000;
}
.button--line.button--neutral-1-20 .button__text {
  color: rgba(5, 5, 5, 0.2);
}
.button--line.button--neutral-1-30 .button__text {
  color: rgba(5, 5, 5, 0.3);
}
.button--line.button--neutral-1-40 .button__text {
  color: rgba(5, 5, 5, 0.4);
}
.button--line.button--neutral-1-60 .button__text {
  color: rgba(5, 5, 5, 0.6);
}
.button--line.button--neutral-1-80 .button__text {
  color: rgba(5, 5, 5, 0.8);
}
.button--line.button--accent-1 .button__text {
  color: #fc5c63;
}
.button--line.button--accent-2 .button__text {
  color: #fc847c;
}
.button--line.button--accent-3 .button__text {
  color: #ffaa98;
}
.button--line.button--accent-4 .button__text {
  color: #ffe7d1;
}
.button--line.button--accent-4-20 .button__text {
  color: rgba(255, 231, 209, 0.2);
}
.button--line.button--accent-4-30 .button__text {
  color: rgba(255, 231, 209, 0.3);
}
.button--line.button--accent-4-40 .button__text {
  color: rgba(255, 231, 209, 0.4);
}
.button--line.button--accent-4-60 .button__text {
  color: rgba(255, 231, 209, 0.6);
}
.button--line.button--accent-4-80 .button__text {
  color: rgba(255, 231, 209, 0.8);
}
.button--line.button--accent-5 .button__text {
  color: #ffaa98;
}
.button--line.button--accent-1-l .button__text {
  color: #fff2f3;
}
.button--line.button--accent-2-l .button__text {
  color: #fdb3ae;
}
.button--line.button--accent-3-l .button__text {
  color: #ffd4cb;
}
.button--line.button--accent-4-l .button__text {
  color: #fff4eb;
}
.button--line.button--accent-5-l .button__text {
  color: #ffbfb2;
}
.button--line.button--accent-1-d .button__text {
  color: #ee040f;
}
.button--line.button--accent-2-d .button__text {
  color: #fa2618;
}
.button--line.button--accent-3-d .button__text {
  color: #ff5632;
}
.button--line.button--accent-4-d .button__text {
  color: #ffb26b;
}
.button--line.button--accent-5-d .button__text {
  color: #ff5632;
}
.button--line.button--success .button__text {
  color: #128c74;
}
.button--line.button--success-l .button__text {
  color: #ddefea;
}
.button--line.button--success-d .button__text {
  color: #0c5f4f;
}
.button--line.button--warning .button__text {
  color: #ff8867;
}
.button--line.button--warning-d .button__text {
  color: #e3401b;
}
.button--line.button--warning-l .button__text {
  color: #ffb190;
}
.button--line.button--error .button__text {
  color: #e52626;
}
.button--line.button--error-d .button__text {
  color: #c70808;
}
.button--line.button--error-l .button__text {
  color: #ff7676;
}
.button--line.button--inherit .button__text {
  color: inherit;
}
.button--line:hover:not(.button--disabled).button--white {
  background-color: rgba(255, 255, 255, 0.2);
}
.button--line:hover:not(.button--disabled).button--facebook {
  background-color: #4267b2;
}
.button--line:hover:not(.button--disabled).button--twitter {
  background-color: #1da1f2;
}
.button--line:hover:not(.button--disabled).button--whatsapp {
  background-color: #25d366;
}
.button--line:hover:not(.button--disabled).button--primary-1 {
  background-color: #d2f8d1;
}
.button--line:hover:not(.button--disabled).button--primary-1-l {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--primary-1-d {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--primary-1-20 {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--primary-1-30 {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--primary-1-40 {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--primary-1-60 {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--primary-1-80 {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--primary-2 {
  background-color: #e8f4f1;
}
.button--line:hover:not(.button--disabled).button--primary-2-l {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--primary-2-d {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--neutral-1 {
  background-color: #f5f5f5;
}
.button--line:hover:not(.button--disabled).button--neutral-1-l {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--neutral-1-d {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--neutral-1-20 {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--neutral-1-30 {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--neutral-1-40 {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--neutral-1-60 {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--neutral-1-80 {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--accent-1 {
  background-color: #fff2f3;
}
.button--line:hover:not(.button--disabled).button--accent-2 {
  background-color: #fdb3ae;
}
.button--line:hover:not(.button--disabled).button--accent-3 {
  background-color: #ffd4cb;
}
.button--line:hover:not(.button--disabled).button--accent-4 {
  background-color: #fff4eb;
}
.button--line:hover:not(.button--disabled).button--accent-4-20 {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--accent-4-30 {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--accent-4-40 {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--accent-4-60 {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--accent-4-80 {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--accent-5 {
  background-color: #ffbfb2;
}
.button--line:hover:not(.button--disabled).button--accent-1-l {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--accent-2-l {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--accent-3-l {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--accent-4-l {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--accent-5-l {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--accent-1-d {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--accent-2-d {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--accent-3-d {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--accent-4-d {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--accent-5-d {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--success {
  background-color: #ddefea;
}
.button--line:hover:not(.button--disabled).button--success-l {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--success-d {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--warning {
  background-color: #ffb190;
}
.button--line:hover:not(.button--disabled).button--warning-d {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--warning-l {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--error {
  background-color: #ff7676;
}
.button--line:hover:not(.button--disabled).button--error-d {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--error-l {
  background-color: #fff;
}
.button--line:hover:not(.button--disabled).button--inherit {
  background-color: inherit;
}
.button--lowercase {
  font-weight: 400;
  text-transform: initial;
}
.button--neutral-1-l .button__text {
  color: #050505;
}
.button--neutral-1-l.button--line {
  border-color: rgba(5, 5, 5, 0.2);
}
.button--neutral-1-l.button--line .button__text {
  color: #050505;
}
.button--social {
  font-size: 24px;
  padding: 0;
  width: 100%;
}
.button--shadow {
  box-shadow: 0 4px 20px 0 rgba(#050505, 0.15);
}
.button--squared {
  border-width: 1px;
  border-radius: 4px;
  background-color: transparent;
  padding: 0 16px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}
@media screen and (min-width: 768px) {
  .button--squared {
    font-size: 16px;
  }
}
.button--squared.button--white {
  border-color: #fff;
}
.button--squared.button--facebook {
  border-color: #4267b2;
}
.button--squared.button--twitter {
  border-color: #1da1f2;
}
.button--squared.button--whatsapp {
  border-color: #25d366;
}
.button--squared.button--primary-1 {
  border-color: #0c806b;
}
.button--squared.button--primary-1-l {
  border-color: #d2f8d1;
}
.button--squared.button--primary-1-d {
  border-color: #006855;
}
.button--squared.button--primary-1-20 {
  border-color: rgba(12, 128, 107, 0.2);
}
.button--squared.button--primary-1-30 {
  border-color: rgba(12, 128, 107, 0.3);
}
.button--squared.button--primary-1-40 {
  border-color: rgba(12, 128, 107, 0.4);
}
.button--squared.button--primary-1-60 {
  border-color: rgba(12, 128, 107, 0.6);
}
.button--squared.button--primary-1-80 {
  border-color: rgba(12, 128, 107, 0.8);
}
.button--squared.button--primary-2 {
  border-color: #8acab7;
}
.button--squared.button--primary-2-l {
  border-color: #e8f4f1;
}
.button--squared.button--primary-2-d {
  border-color: #42b491;
}
.button--squared.button--neutral-1 {
  border-color: #050505;
}
.button--squared.button--neutral-1-l {
  border-color: #f5f5f5;
}
.button--squared.button--neutral-1-d {
  border-color: #000000;
}
.button--squared.button--neutral-1-20 {
  border-color: rgba(5, 5, 5, 0.2);
}
.button--squared.button--neutral-1-30 {
  border-color: rgba(5, 5, 5, 0.3);
}
.button--squared.button--neutral-1-40 {
  border-color: rgba(5, 5, 5, 0.4);
}
.button--squared.button--neutral-1-60 {
  border-color: rgba(5, 5, 5, 0.6);
}
.button--squared.button--neutral-1-80 {
  border-color: rgba(5, 5, 5, 0.8);
}
.button--squared.button--accent-1 {
  border-color: #fc5c63;
}
.button--squared.button--accent-2 {
  border-color: #fc847c;
}
.button--squared.button--accent-3 {
  border-color: #ffaa98;
}
.button--squared.button--accent-4 {
  border-color: #ffe7d1;
}
.button--squared.button--accent-4-20 {
  border-color: rgba(255, 231, 209, 0.2);
}
.button--squared.button--accent-4-30 {
  border-color: rgba(255, 231, 209, 0.3);
}
.button--squared.button--accent-4-40 {
  border-color: rgba(255, 231, 209, 0.4);
}
.button--squared.button--accent-4-60 {
  border-color: rgba(255, 231, 209, 0.6);
}
.button--squared.button--accent-4-80 {
  border-color: rgba(255, 231, 209, 0.8);
}
.button--squared.button--accent-5 {
  border-color: #ffaa98;
}
.button--squared.button--accent-1-l {
  border-color: #fff2f3;
}
.button--squared.button--accent-2-l {
  border-color: #fdb3ae;
}
.button--squared.button--accent-3-l {
  border-color: #ffd4cb;
}
.button--squared.button--accent-4-l {
  border-color: #fff4eb;
}
.button--squared.button--accent-5-l {
  border-color: #ffbfb2;
}
.button--squared.button--accent-1-d {
  border-color: #ee040f;
}
.button--squared.button--accent-2-d {
  border-color: #fa2618;
}
.button--squared.button--accent-3-d {
  border-color: #ff5632;
}
.button--squared.button--accent-4-d {
  border-color: #ffb26b;
}
.button--squared.button--accent-5-d {
  border-color: #ff5632;
}
.button--squared.button--success {
  border-color: #128c74;
}
.button--squared.button--success-l {
  border-color: #ddefea;
}
.button--squared.button--success-d {
  border-color: #0c5f4f;
}
.button--squared.button--warning {
  border-color: #ff8867;
}
.button--squared.button--warning-d {
  border-color: #e3401b;
}
.button--squared.button--warning-l {
  border-color: #ffb190;
}
.button--squared.button--error {
  border-color: #e52626;
}
.button--squared.button--error-d {
  border-color: #c70808;
}
.button--squared.button--error-l {
  border-color: #ff7676;
}
.button--squared.button--inherit {
  border-color: inherit;
}
.button--squared.button--white .button__text {
  color: #fff;
}
.button--squared.button--facebook .button__text {
  color: #4267b2;
}
.button--squared.button--twitter .button__text {
  color: #1da1f2;
}
.button--squared.button--whatsapp .button__text {
  color: #25d366;
}
.button--squared.button--primary-1 .button__text {
  color: #0c806b;
}
.button--squared.button--primary-1-l .button__text {
  color: #d2f8d1;
}
.button--squared.button--primary-1-d .button__text {
  color: #006855;
}
.button--squared.button--primary-1-20 .button__text {
  color: rgba(12, 128, 107, 0.2);
}
.button--squared.button--primary-1-30 .button__text {
  color: rgba(12, 128, 107, 0.3);
}
.button--squared.button--primary-1-40 .button__text {
  color: rgba(12, 128, 107, 0.4);
}
.button--squared.button--primary-1-60 .button__text {
  color: rgba(12, 128, 107, 0.6);
}
.button--squared.button--primary-1-80 .button__text {
  color: rgba(12, 128, 107, 0.8);
}
.button--squared.button--primary-2 .button__text {
  color: #8acab7;
}
.button--squared.button--primary-2-l .button__text {
  color: #e8f4f1;
}
.button--squared.button--primary-2-d .button__text {
  color: #42b491;
}
.button--squared.button--neutral-1 .button__text {
  color: #050505;
}
.button--squared.button--neutral-1-l .button__text {
  color: #f5f5f5;
}
.button--squared.button--neutral-1-d .button__text {
  color: #000000;
}
.button--squared.button--neutral-1-20 .button__text {
  color: rgba(5, 5, 5, 0.2);
}
.button--squared.button--neutral-1-30 .button__text {
  color: rgba(5, 5, 5, 0.3);
}
.button--squared.button--neutral-1-40 .button__text {
  color: rgba(5, 5, 5, 0.4);
}
.button--squared.button--neutral-1-60 .button__text {
  color: rgba(5, 5, 5, 0.6);
}
.button--squared.button--neutral-1-80 .button__text {
  color: rgba(5, 5, 5, 0.8);
}
.button--squared.button--accent-1 .button__text {
  color: #fc5c63;
}
.button--squared.button--accent-2 .button__text {
  color: #fc847c;
}
.button--squared.button--accent-3 .button__text {
  color: #ffaa98;
}
.button--squared.button--accent-4 .button__text {
  color: #ffe7d1;
}
.button--squared.button--accent-4-20 .button__text {
  color: rgba(255, 231, 209, 0.2);
}
.button--squared.button--accent-4-30 .button__text {
  color: rgba(255, 231, 209, 0.3);
}
.button--squared.button--accent-4-40 .button__text {
  color: rgba(255, 231, 209, 0.4);
}
.button--squared.button--accent-4-60 .button__text {
  color: rgba(255, 231, 209, 0.6);
}
.button--squared.button--accent-4-80 .button__text {
  color: rgba(255, 231, 209, 0.8);
}
.button--squared.button--accent-5 .button__text {
  color: #ffaa98;
}
.button--squared.button--accent-1-l .button__text {
  color: #fff2f3;
}
.button--squared.button--accent-2-l .button__text {
  color: #fdb3ae;
}
.button--squared.button--accent-3-l .button__text {
  color: #ffd4cb;
}
.button--squared.button--accent-4-l .button__text {
  color: #fff4eb;
}
.button--squared.button--accent-5-l .button__text {
  color: #ffbfb2;
}
.button--squared.button--accent-1-d .button__text {
  color: #ee040f;
}
.button--squared.button--accent-2-d .button__text {
  color: #fa2618;
}
.button--squared.button--accent-3-d .button__text {
  color: #ff5632;
}
.button--squared.button--accent-4-d .button__text {
  color: #ffb26b;
}
.button--squared.button--accent-5-d .button__text {
  color: #ff5632;
}
.button--squared.button--success .button__text {
  color: #128c74;
}
.button--squared.button--success-l .button__text {
  color: #ddefea;
}
.button--squared.button--success-d .button__text {
  color: #0c5f4f;
}
.button--squared.button--warning .button__text {
  color: #ff8867;
}
.button--squared.button--warning-d .button__text {
  color: #e3401b;
}
.button--squared.button--warning-l .button__text {
  color: #ffb190;
}
.button--squared.button--error .button__text {
  color: #e52626;
}
.button--squared.button--error-d .button__text {
  color: #c70808;
}
.button--squared.button--error-l .button__text {
  color: #ff7676;
}
.button--squared.button--inherit .button__text {
  color: inherit;
}
.button--squared:hover:not(.button--disabled).button--white {
  background-color: rgba(255, 255, 255, 0.2);
}
.button--squared:hover:not(.button--disabled).button--facebook {
  background-color: #4267b2;
}
.button--squared:hover:not(.button--disabled).button--twitter {
  background-color: #1da1f2;
}
.button--squared:hover:not(.button--disabled).button--whatsapp {
  background-color: #25d366;
}
.button--squared:hover:not(.button--disabled).button--primary-1 {
  background-color: #d2f8d1;
}
.button--squared:hover:not(.button--disabled).button--primary-1-l {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--primary-1-d {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--primary-1-20 {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--primary-1-30 {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--primary-1-40 {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--primary-1-60 {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--primary-1-80 {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--primary-2 {
  background-color: #e8f4f1;
}
.button--squared:hover:not(.button--disabled).button--primary-2-l {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--primary-2-d {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--neutral-1 {
  background-color: #f5f5f5;
}
.button--squared:hover:not(.button--disabled).button--neutral-1-l {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--neutral-1-d {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--neutral-1-20 {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--neutral-1-30 {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--neutral-1-40 {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--neutral-1-60 {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--neutral-1-80 {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--accent-1 {
  background-color: #fff2f3;
}
.button--squared:hover:not(.button--disabled).button--accent-2 {
  background-color: #fdb3ae;
}
.button--squared:hover:not(.button--disabled).button--accent-3 {
  background-color: #ffd4cb;
}
.button--squared:hover:not(.button--disabled).button--accent-4 {
  background-color: #fff4eb;
}
.button--squared:hover:not(.button--disabled).button--accent-4-20 {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--accent-4-30 {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--accent-4-40 {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--accent-4-60 {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--accent-4-80 {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--accent-5 {
  background-color: #ffbfb2;
}
.button--squared:hover:not(.button--disabled).button--accent-1-l {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--accent-2-l {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--accent-3-l {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--accent-4-l {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--accent-5-l {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--accent-1-d {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--accent-2-d {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--accent-3-d {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--accent-4-d {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--accent-5-d {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--success {
  background-color: #ddefea;
}
.button--squared:hover:not(.button--disabled).button--success-l {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--success-d {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--warning {
  background-color: #ffb190;
}
.button--squared:hover:not(.button--disabled).button--warning-d {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--warning-l {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--error {
  background-color: #ff7676;
}
.button--squared:hover:not(.button--disabled).button--error-d {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--error-l {
  background-color: #fff;
}
.button--squared:hover:not(.button--disabled).button--inherit {
  background-color: inherit;
}
.button--squared-icon {
  border-radius: 0px;
  display: block;
  padding: 0;
  width: 32px;
  height: 32px;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .button--squared-icon {
    width: 48px;
    height: 48px;
  }
}
.button--squared-icon.button--small {
  border-radius: 4px;
}
@media only screen and (min-width: 768px) {
  .button--squared-icon.button--small {
    width: 32px;
    height: 32px;
  }
}
.button--squared-icon .icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.button--transparent {
  background: transparent;
}
.button--transparent:hover {
  box-shadow: none;
  background: transparent;
}
.button--white .button__text {
  color: #050505;
}
.button--white.button:hover {
  background: #fff;
}
.button--white.button--line {
  border-color: #050505;
}
.button--white.button--line.button:hover {
  background-color: #f5f5f5;
}
.button--white.button--line .button__text {
  color: #050505;
}
.button--scale-on-hover {
  transition: transform 300ms ease-in-out;
}
.button--scale-on-hover:hover {
  transform: scale(1.1);
}
