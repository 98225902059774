.atom--content--white {
  background-color: #fff;
}

.atom--content--facebook {
  background-color: #4267b2;
}

.atom--content--twitter {
  background-color: #1da1f2;
}

.atom--content--whatsapp {
  background-color: #25d366;
}

.atom--content--primary-1 {
  background-color: #0c806b;
}

.atom--content--primary-1-l {
  background-color: #d2f8d1;
}

.atom--content--primary-1-d {
  background-color: #006855;
}

.atom--content--primary-1-20 {
  background-color: rgba(12, 128, 107, 0.2);
}

.atom--content--primary-1-30 {
  background-color: rgba(12, 128, 107, 0.3);
}

.atom--content--primary-1-40 {
  background-color: rgba(12, 128, 107, 0.4);
}

.atom--content--primary-1-60 {
  background-color: rgba(12, 128, 107, 0.6);
}

.atom--content--primary-1-80 {
  background-color: rgba(12, 128, 107, 0.8);
}

.atom--content--primary-2 {
  background-color: #8acab7;
}

.atom--content--primary-2-l {
  background-color: #e8f4f1;
}

.atom--content--primary-2-d {
  background-color: #42b491;
}

.atom--content--neutral-1 {
  background-color: #050505;
}

.atom--content--neutral-1-l {
  background-color: #f5f5f5;
}

.atom--content--neutral-1-d {
  background-color: #000000;
}

.atom--content--neutral-1-20 {
  background-color: rgba(5, 5, 5, 0.2);
}

.atom--content--neutral-1-30 {
  background-color: rgba(5, 5, 5, 0.3);
}

.atom--content--neutral-1-40 {
  background-color: rgba(5, 5, 5, 0.4);
}

.atom--content--neutral-1-60 {
  background-color: rgba(5, 5, 5, 0.6);
}

.atom--content--neutral-1-80 {
  background-color: rgba(5, 5, 5, 0.8);
}

.atom--content--accent-1 {
  background-color: #fc5c63;
}

.atom--content--accent-2 {
  background-color: #fc847c;
}

.atom--content--accent-3 {
  background-color: #ffaa98;
}

.atom--content--accent-4 {
  background-color: #ffe7d1;
}

.atom--content--accent-4-20 {
  background-color: rgba(255, 231, 209, 0.2);
}

.atom--content--accent-4-30 {
  background-color: rgba(255, 231, 209, 0.3);
}

.atom--content--accent-4-40 {
  background-color: rgba(255, 231, 209, 0.4);
}

.atom--content--accent-4-60 {
  background-color: rgba(255, 231, 209, 0.6);
}

.atom--content--accent-4-80 {
  background-color: rgba(255, 231, 209, 0.8);
}

.atom--content--accent-5 {
  background-color: #ffaa98;
}

.atom--content--accent-1-l {
  background-color: #fff2f3;
}

.atom--content--accent-2-l {
  background-color: #fdb3ae;
}

.atom--content--accent-3-l {
  background-color: #ffd4cb;
}

.atom--content--accent-4-l {
  background-color: #fff4eb;
}

.atom--content--accent-5-l {
  background-color: #ffbfb2;
}

.atom--content--accent-1-d {
  background-color: #ee040f;
}

.atom--content--accent-2-d {
  background-color: #fa2618;
}

.atom--content--accent-3-d {
  background-color: #ff5632;
}

.atom--content--accent-4-d {
  background-color: #ffb26b;
}

.atom--content--accent-5-d {
  background-color: #ff5632;
}

.atom--content--success {
  background-color: #128c74;
}

.atom--content--success-l {
  background-color: #ddefea;
}

.atom--content--success-d {
  background-color: #0c5f4f;
}

.atom--content--warning {
  background-color: #ff8867;
}

.atom--content--warning-d {
  background-color: #e3401b;
}

.atom--content--warning-l {
  background-color: #ffb190;
}

.atom--content--error {
  background-color: #e52626;
}

.atom--content--error-d {
  background-color: #c70808;
}

.atom--content--error-l {
  background-color: #ff7676;
}

.atom--content--inherit {
  background-color: inherit;
}

.atom--content--responsive {
  padding: 16px;
}
@media screen and (min-width: 768px) {
  .atom--content--responsive {
    padding: 24px;
  }
}

.atom--content--x-small {
  padding: 8px 16px;
}

.atom--content--small {
  padding: 16px;
}

.atom--content--center {
  text-align: center;
}

.atom--content--full-height {
  height: 100%;
}
