.directions-client-card {
  height: 100%;
}
.directions-client-card--title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.directions-client-card--content {
  margin-bottom: 64px;
}
.directions-client-card--content a {
  cursor: pointer;
  text-decoration: underline;
  color: #0c806b;
}
.directions-client-card--button {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
.directions-client-card .card,
.directions-client-card .card--content {
  height: 100%;
}
