.validate-simulation-page {
  padding-top: 16px;
}
@media screen and (min-width: 1024px) {
  .validate-simulation-page {
    padding-top: 40px;
  }
}
.validate-simulation-page--bubble {
  margin-bottom: 16px;
}
@media screen and (min-width: 768px) {
  .validate-simulation-page--bubble {
    margin-right: 40px;
    margin-bottom: 40px;
  }
}
.validate-simulation-page--title {
  margin-bottom: 24px;
}
@media screen and (min-width: 768px) {
  .validate-simulation-page--title {
    margin-bottom: 32px;
    margin-left: 56px;
  }
}
@media screen and (min-width: 600px) {
  .validate-simulation-page--summaries {
    display: flex;
  }
}
@media screen and (min-width: 768px) {
  .validate-simulation-page--summaries {
    max-width: 768px;
    margin-left: 56px;
  }
}
.validate-simulation-page--summaries--summary {
  margin-bottom: 16px;
}
@media screen and (min-width: 600px) {
  .validate-simulation-page--summaries--summary {
    flex-grow: 1;
  }
  .validate-simulation-page--summaries--summary--new {
    order: 1;
    margin-left: 16px;
  }
}
