/* Pensum Pro — Black — normal */
@font-face {
  font-display: swap;
  font-family: 'Pensum Pro';
  font-style: normal;
  font-weight: 900;
  src: local('PensumPro-Black'), url('./Pensum-Pro-Black.woff2') format('woff2'),
    url('./Pensum-Pro-Black.woff') format('woff');
}

/* Pensum Pro — Book — italic */
@font-face {
  font-display: swap;
  font-family: 'Pensum Pro';
  font-style: italic;
  font-weight: 450;
  src: local('PensumPro-BookItalic'), url('./Pensum-Pro-Book-Italic.woff2') format('woff2'),
    url('./Pensum-Pro-Book-Italic.woff') format('woff');
}
