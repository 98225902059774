.list-item--white {
  color: #fff;
}

.list-item--facebook {
  color: #4267b2;
}

.list-item--twitter {
  color: #1da1f2;
}

.list-item--whatsapp {
  color: #25d366;
}

.list-item--primary-1 {
  color: #0c806b;
}

.list-item--primary-1-l {
  color: #d2f8d1;
}

.list-item--primary-1-d {
  color: #006855;
}

.list-item--primary-1-20 {
  color: rgba(12, 128, 107, 0.2);
}

.list-item--primary-1-30 {
  color: rgba(12, 128, 107, 0.3);
}

.list-item--primary-1-40 {
  color: rgba(12, 128, 107, 0.4);
}

.list-item--primary-1-60 {
  color: rgba(12, 128, 107, 0.6);
}

.list-item--primary-1-80 {
  color: rgba(12, 128, 107, 0.8);
}

.list-item--primary-2 {
  color: #8acab7;
}

.list-item--primary-2-l {
  color: #e8f4f1;
}

.list-item--primary-2-d {
  color: #42b491;
}

.list-item--neutral-1 {
  color: #050505;
}

.list-item--neutral-1-l {
  color: #f5f5f5;
}

.list-item--neutral-1-d {
  color: #000000;
}

.list-item--neutral-1-20 {
  color: rgba(5, 5, 5, 0.2);
}

.list-item--neutral-1-30 {
  color: rgba(5, 5, 5, 0.3);
}

.list-item--neutral-1-40 {
  color: rgba(5, 5, 5, 0.4);
}

.list-item--neutral-1-60 {
  color: rgba(5, 5, 5, 0.6);
}

.list-item--neutral-1-80 {
  color: rgba(5, 5, 5, 0.8);
}

.list-item--accent-1 {
  color: #fc5c63;
}

.list-item--accent-2 {
  color: #fc847c;
}

.list-item--accent-3 {
  color: #ffaa98;
}

.list-item--accent-4 {
  color: #ffe7d1;
}

.list-item--accent-4-20 {
  color: rgba(255, 231, 209, 0.2);
}

.list-item--accent-4-30 {
  color: rgba(255, 231, 209, 0.3);
}

.list-item--accent-4-40 {
  color: rgba(255, 231, 209, 0.4);
}

.list-item--accent-4-60 {
  color: rgba(255, 231, 209, 0.6);
}

.list-item--accent-4-80 {
  color: rgba(255, 231, 209, 0.8);
}

.list-item--accent-5 {
  color: #ffaa98;
}

.list-item--accent-1-l {
  color: #fff2f3;
}

.list-item--accent-2-l {
  color: #fdb3ae;
}

.list-item--accent-3-l {
  color: #ffd4cb;
}

.list-item--accent-4-l {
  color: #fff4eb;
}

.list-item--accent-5-l {
  color: #ffbfb2;
}

.list-item--accent-1-d {
  color: #ee040f;
}

.list-item--accent-2-d {
  color: #fa2618;
}

.list-item--accent-3-d {
  color: #ff5632;
}

.list-item--accent-4-d {
  color: #ffb26b;
}

.list-item--accent-5-d {
  color: #ff5632;
}

.list-item--success {
  color: #128c74;
}

.list-item--success-l {
  color: #ddefea;
}

.list-item--success-d {
  color: #0c5f4f;
}

.list-item--warning {
  color: #ff8867;
}

.list-item--warning-d {
  color: #e3401b;
}

.list-item--warning-l {
  color: #ffb190;
}

.list-item--error {
  color: #e52626;
}

.list-item--error-d {
  color: #c70808;
}

.list-item--error-l {
  color: #ff7676;
}

.list-item--inherit {
  color: inherit;
}

.list-item:focus {
  outline: 0;
  background-color: #f5f5f5;
}

.list-item:last-child .divider {
  display: none;
}

.list-item--default .list-item--content {
  padding: 16px;
}

.list-item--pointer {
  cursor: pointer;
}
