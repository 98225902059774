.autocomplete--dialog-content {
  margin: auto;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .autocomplete--dialog-content {
    height: 100%;
    max-height: 80vh;
  }
}
