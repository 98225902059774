.spinner--overlay {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.8);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  color: #0c806b;
  margin: auto;
}
.spinner--white {
  color: #fff;
}
.spinner--wrapper {
  text-align: center;
}
.spinner--content {
  display: inline-block;
  margin-top: 40px;
}
.spinner--small .spinner--prism {
  width: 24px;
  height: 24px;
}
.spinner--small .loader-circle {
  margin-top: -12px;
  margin-left: -12px;
  width: 24px;
  height: 24px;
}
.spinner--small .loader-line-mask {
  margin-top: -12px;
  margin-left: -12px;
  width: 12px;
  height: 24px;
  transform-origin: 12px 12px;
}
.spinner--small .loader-line-mask .loader-line {
  border-width: 2px;
  width: 24px;
  height: 24px;
}
.spinner--medium .spinner--prism {
  width: 64px;
  height: 64px;
}
.spinner--medium .loader-circle {
  margin-top: -32px;
  margin-left: -32px;
  width: 64px;
  height: 64px;
}
.spinner--medium .loader-line-mask {
  margin-top: -32px;
  margin-left: -32px;
  width: 32px;
  height: 64px;
  transform-origin: 32px 32px;
}
.spinner--medium .loader-line-mask .loader-line {
  border-width: 3px;
  width: 64px;
  height: 64px;
}
.spinner--large .spinner--prism {
  width: 96px;
  height: 96px;
}
.spinner--large .loader-circle {
  margin-top: -48px;
  margin-left: -48px;
  width: 96px;
  height: 96px;
}
.spinner--large .loader-line-mask {
  margin-top: -48px;
  margin-left: -48px;
  width: 48px;
  height: 96px;
  transform-origin: 48px 48px;
}
.spinner--large .loader-line-mask .loader-line {
  border-width: 5px;
  width: 96px;
  height: 96px;
}

.spinner--prism {
  position: relative;
}
.spinner--prism .vertical-centered-box {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
}
.spinner--prism .vertical-centered-box:after {
  display: inline-block;
  margin-right: -0.25em;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.spinner--prism .vertical-centered-box .content {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  font-size: 0;
}
.spinner--prism * {
  transition: all 0.3s;
}

.loader-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
}

.loader-line-mask {
  position: absolute;
  top: 50%;
  left: 50%;
  animation: rotate 1.5s infinite linear;
  overflow: hidden;
  -webkit-mask-image: -webkit-linear-gradient(top, black, rgba(0, 0, 0, 0));
}
.loader-line-mask .loader-line {
  border-style: solid;
  border-color: currentColor;
  border-radius: 50%;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
