.swipe {
  padding: 0 12px;
}
@media screen and (min-width: 768px) {
  .swipe {
    padding: 0 20px;
  }
}
.swipe--view {
  overflow-x: visible !important;
}
.swipe--dots {
  margin: 0 16px;
  padding: 16px 0;
}
.swipe__slide {
  display: flex;
}

.view {
  display: flex;
  flex-grow: 1;
  position: relative;
  padding: 0 4px;
  overflow: visible;
}
.view--content {
  display: flex;
  flex-grow: 1;
  position: relative;
  transition: all 300ms linear;
}

.swipe--full {
  padding: 0;
}
.swipe--full--right {
  margin-right: -24px;
}
.swipe--full--right .view {
  margin-right: 24px;
}
.swipe--full--left {
  margin-left: -24px;
}
.swipe--full--left .view {
  margin-left: 24px;
}
.swipe--full--center {
  margin-right: -12px;
  margin-left: -12px;
}
.swipe--full--center .view {
  margin-right: 12px;
  margin-left: 12px;
}
.swipe--full .swipe--dots--right {
  margin-right: 24px;
}
.swipe--full .swipe--dots--left {
  margin-left: 24px;
}
.swipe--full .swipe--dots--center {
  margin-right: 12px;
  margin-left: 12px;
}
.swipe--full .view {
  padding: 0;
}
