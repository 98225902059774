.spinner-content {
  margin: 16px;
  max-width: 340px;
  text-align: center;
}
.spinner-content--title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}
.spinner-content--title--emoji {
  margin-right: 16px;
}
