.directions-simulation-card {
  height: 100%;
}
.directions-simulation-card--title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.directions-simulation-card--title--img {
  margin-right: 16px;
}
@media screen and (min-width: 768px) {
  .directions-simulation-card--title--img {
    margin-right: 24px;
  }
}
.directions-simulation-card--content {
  margin-bottom: 64px;
}
.directions-simulation-card--button {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
