.list {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  list-style: none;
}
.list--selectfield--dropdown {
  box-shadow: 0 4px 20px 0 rgba(#050505, 0.15);
  border-radius: 8px;
  background: #fff;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .list--selectfield--dropdown {
    position: absolute;
    top: 32px;
    left: -8px;
    z-index: 999;
    margin-bottom: 24px;
    min-width: calc(100% + 16px);
    max-height: 293px;
    overflow-y: auto;
  }
}
.list--selectfield--dropdown .list-item {
  background-color: #fff;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .list--selectfield--dropdown .list-item {
    transition: all 300ms linear;
    cursor: pointer;
    white-space: nowrap;
  }
}
.list--selectfield--dropdown .list-item--content {
  box-sizing: content-box;
  padding: 12px 16px;
  height: auto;
  line-height: 24px;
  min-height: 24px;
}
.list--selectfield--dropdown .list-item:hover {
  background-color: #f5f5f5;
}
.list--selectfield--dropdown .list-item .icon {
  margin-left: 16px;
}

.list--default {
  background-color: #fff;
}

.list--documents {
  background-color: transparent;
}
.list--documents .list-item:hover {
  color: #0c806b;
}

.list--uploaded-documents {
  background-color: transparent;
}
.list--uploaded-documents .list-item .list-item--content {
  position: relative;
}
