body {
  letter-spacing: 0;
  font-family: Maison Neue, sans-serif;
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  background-color: #fff;
  color: #050505;
}

h1,
h2 {
  font-family: Maison Neue, sans-serif;
  font-weight: 900;
  line-height: 32px;
  letter-spacing: 0.2px;
  font-size: 24px;
}

@media only screen and (min-width: 768px) {
  h1,
  h2 {
    line-height: 40px;
    letter-spacing: 0.3px;
    font-size: 32px;
  }
}

h3,
h4,
h5,
h6 {
  font-family: Maison Neue, sans-serif;
  font-weight: 900;
  line-height: 26px;
  letter-spacing: 0.2px;
  font-size: 18px;
}

.accent-color {
  color: #0c806b;
}

.text-success {
  color: #128c74;
}

.text-error {
  color: #e52626;
}

.text-warning {
  color: #ff8867;
}

.wrapper {
  margin: 0 auto;
  max-width: 1240px;
}
