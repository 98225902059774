/* Maison Neue — Black — normal — latin */
@font-face {
  font-display: swap;
  font-family: 'Maison Neue';
  font-style: normal;
  font-weight: 900;
  src: local('Maison Neue Black'), local('MaisonNeue-Black'), url('./MaisonNeue-Black.latin.woff2') format('woff2'),
    url('./MaisonNeue-Black.latin.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Maison Neue — Black — normal — latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Maison Neue';
  font-style: normal;
  font-weight: 900;
  src: local('Maison Neue Black'), local('MaisonNeue-Black'), url('./MaisonNeue-Black.latin-ext.woff2') format('woff2'),
    url('./MaisonNeue-Black.latin-ext.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Maison Neue — Bold — normal — latin */
@font-face {
  font-display: swap;
  font-family: 'Maison Neue';
  font-style: normal;
  font-weight: 700;
  src: local('Maison Neue Bold'), local('MaisonNeue-Bold'), url('./MaisonNeue-Bold.latin.woff2') format('woff2'),
    url('./MaisonNeue-Bold.latin.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Maison Neue — Bold — normal — latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Maison Neue';
  font-style: normal;
  font-weight: 700;
  src: local('Maison Neue Bold'), local('MaisonNeue-Bold'), url('./MaisonNeue-Bold.latin-ext.woff2') format('woff2'),
    url('./MaisonNeue-Bold.latin-ext.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Maison Neue — Book — normal — latin */
@font-face {
  font-display: swap;
  font-family: 'Maison Neue';
  font-style: normal;
  font-weight: 400;
  src: local('Maison Neue Book'), local('MaisonNeue-Book'), url('./MaisonNeue-Book.latin.woff2') format('woff2'),
    url('./MaisonNeue-Book.latin.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Maison Neue — Book — normal — latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Maison Neue';
  font-style: normal;
  font-weight: 400;
  src: local('Maison Neue Book'), local('MaisonNeue-Book'), url('./MaisonNeue-Book.latin-ext.woff2') format('woff2'),
    url('./MaisonNeue-Book.latin-ext.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Maison Neue — Medium — normal — latin */
@font-face {
  font-display: swap;
  font-family: 'Maison Neue';
  font-style: normal;
  font-weight: 500;
  src: local('Maison Neue Medium'), local('MaisonNeue-Medium'), url('./MaisonNeue-Medium.latin.woff2') format('woff2'),
    url('./MaisonNeue-Medium.latin.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Maison Neue — Medium — normal — latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Maison Neue';
  font-style: normal;
  font-weight: 500;
  src: local('Maison Neue Medium'), local('MaisonNeue-Medium'),
    url('./MaisonNeue-Medium.latin-ext.woff2') format('woff2'), url('./MaisonNeue-Medium.latin-ext.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
