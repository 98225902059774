.graph__captions--mobile .graph__caption {
  transition: opacity 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: opacity;
}
.graph__captions--mobile .graph__caption--dimmed {
  opacity: 0.5;
}
.graph__captions--mobile .graph__caption__bullet {
  border-radius: 2px;
  display: block;
  height: 8px;
  margin-right: 16px;
  width: 8px;
}
.graph__captions--mobile .graph__caption__bullet--white {
  background-color: #fff;
}
.graph__captions--mobile .graph__caption__bullet--facebook {
  background-color: #4267b2;
}
.graph__captions--mobile .graph__caption__bullet--twitter {
  background-color: #1da1f2;
}
.graph__captions--mobile .graph__caption__bullet--whatsapp {
  background-color: #25d366;
}
.graph__captions--mobile .graph__caption__bullet--primary-1 {
  background-color: #0c806b;
}
.graph__captions--mobile .graph__caption__bullet--primary-1-l {
  background-color: #d2f8d1;
}
.graph__captions--mobile .graph__caption__bullet--primary-1-d {
  background-color: #006855;
}
.graph__captions--mobile .graph__caption__bullet--primary-1-20 {
  background-color: rgba(12, 128, 107, 0.2);
}
.graph__captions--mobile .graph__caption__bullet--primary-1-30 {
  background-color: rgba(12, 128, 107, 0.3);
}
.graph__captions--mobile .graph__caption__bullet--primary-1-40 {
  background-color: rgba(12, 128, 107, 0.4);
}
.graph__captions--mobile .graph__caption__bullet--primary-1-60 {
  background-color: rgba(12, 128, 107, 0.6);
}
.graph__captions--mobile .graph__caption__bullet--primary-1-80 {
  background-color: rgba(12, 128, 107, 0.8);
}
.graph__captions--mobile .graph__caption__bullet--primary-2 {
  background-color: #8acab7;
}
.graph__captions--mobile .graph__caption__bullet--primary-2-l {
  background-color: #e8f4f1;
}
.graph__captions--mobile .graph__caption__bullet--primary-2-d {
  background-color: #42b491;
}
.graph__captions--mobile .graph__caption__bullet--neutral-1 {
  background-color: #050505;
}
.graph__captions--mobile .graph__caption__bullet--neutral-1-l {
  background-color: #f5f5f5;
}
.graph__captions--mobile .graph__caption__bullet--neutral-1-d {
  background-color: #000000;
}
.graph__captions--mobile .graph__caption__bullet--neutral-1-20 {
  background-color: rgba(5, 5, 5, 0.2);
}
.graph__captions--mobile .graph__caption__bullet--neutral-1-30 {
  background-color: rgba(5, 5, 5, 0.3);
}
.graph__captions--mobile .graph__caption__bullet--neutral-1-40 {
  background-color: rgba(5, 5, 5, 0.4);
}
.graph__captions--mobile .graph__caption__bullet--neutral-1-60 {
  background-color: rgba(5, 5, 5, 0.6);
}
.graph__captions--mobile .graph__caption__bullet--neutral-1-80 {
  background-color: rgba(5, 5, 5, 0.8);
}
.graph__captions--mobile .graph__caption__bullet--accent-1 {
  background-color: #fc5c63;
}
.graph__captions--mobile .graph__caption__bullet--accent-2 {
  background-color: #fc847c;
}
.graph__captions--mobile .graph__caption__bullet--accent-3 {
  background-color: #ffaa98;
}
.graph__captions--mobile .graph__caption__bullet--accent-4 {
  background-color: #ffe7d1;
}
.graph__captions--mobile .graph__caption__bullet--accent-4-20 {
  background-color: rgba(255, 231, 209, 0.2);
}
.graph__captions--mobile .graph__caption__bullet--accent-4-30 {
  background-color: rgba(255, 231, 209, 0.3);
}
.graph__captions--mobile .graph__caption__bullet--accent-4-40 {
  background-color: rgba(255, 231, 209, 0.4);
}
.graph__captions--mobile .graph__caption__bullet--accent-4-60 {
  background-color: rgba(255, 231, 209, 0.6);
}
.graph__captions--mobile .graph__caption__bullet--accent-4-80 {
  background-color: rgba(255, 231, 209, 0.8);
}
.graph__captions--mobile .graph__caption__bullet--accent-5 {
  background-color: #ffaa98;
}
.graph__captions--mobile .graph__caption__bullet--accent-1-l {
  background-color: #fff2f3;
}
.graph__captions--mobile .graph__caption__bullet--accent-2-l {
  background-color: #fdb3ae;
}
.graph__captions--mobile .graph__caption__bullet--accent-3-l {
  background-color: #ffd4cb;
}
.graph__captions--mobile .graph__caption__bullet--accent-4-l {
  background-color: #fff4eb;
}
.graph__captions--mobile .graph__caption__bullet--accent-5-l {
  background-color: #ffbfb2;
}
.graph__captions--mobile .graph__caption__bullet--accent-1-d {
  background-color: #ee040f;
}
.graph__captions--mobile .graph__caption__bullet--accent-2-d {
  background-color: #fa2618;
}
.graph__captions--mobile .graph__caption__bullet--accent-3-d {
  background-color: #ff5632;
}
.graph__captions--mobile .graph__caption__bullet--accent-4-d {
  background-color: #ffb26b;
}
.graph__captions--mobile .graph__caption__bullet--accent-5-d {
  background-color: #ff5632;
}
.graph__captions--mobile .graph__caption__bullet--success {
  background-color: #128c74;
}
.graph__captions--mobile .graph__caption__bullet--success-l {
  background-color: #ddefea;
}
.graph__captions--mobile .graph__caption__bullet--success-d {
  background-color: #0c5f4f;
}
.graph__captions--mobile .graph__caption__bullet--warning {
  background-color: #ff8867;
}
.graph__captions--mobile .graph__caption__bullet--warning-d {
  background-color: #e3401b;
}
.graph__captions--mobile .graph__caption__bullet--warning-l {
  background-color: #ffb190;
}
.graph__captions--mobile .graph__caption__bullet--error {
  background-color: #e52626;
}
.graph__captions--mobile .graph__caption__bullet--error-d {
  background-color: #c70808;
}
.graph__captions--mobile .graph__caption__bullet--error-l {
  background-color: #ff7676;
}
.graph__captions--mobile .graph__caption__bullet--inherit {
  background-color: inherit;
}
.graph__captions--mobile .graph__caption__price {
  color: #999999;
  margin-left: 24px;
}
.graph__captions--mobile .graph__caption__title {
  align-items: center;
  display: flex;
  margin-right: 16px;
}

.graph__captions--mobile .graph__caption + .graph__caption {
  margin-top: 8px;
}

.graph__captions--top {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.graph__captions--top .graph__caption {
  border-style: solid;
  padding: 0 8px;
  transition: opacity 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: opacity;
  white-space: nowrap;
}
.graph__captions--top .graph__caption--white {
  border-color: #fff;
}
.graph__captions--top .graph__caption--facebook {
  border-color: #4267b2;
}
.graph__captions--top .graph__caption--twitter {
  border-color: #1da1f2;
}
.graph__captions--top .graph__caption--whatsapp {
  border-color: #25d366;
}
.graph__captions--top .graph__caption--primary-1 {
  border-color: #0c806b;
}
.graph__captions--top .graph__caption--primary-1-l {
  border-color: #d2f8d1;
}
.graph__captions--top .graph__caption--primary-1-d {
  border-color: #006855;
}
.graph__captions--top .graph__caption--primary-1-20 {
  border-color: rgba(12, 128, 107, 0.2);
}
.graph__captions--top .graph__caption--primary-1-30 {
  border-color: rgba(12, 128, 107, 0.3);
}
.graph__captions--top .graph__caption--primary-1-40 {
  border-color: rgba(12, 128, 107, 0.4);
}
.graph__captions--top .graph__caption--primary-1-60 {
  border-color: rgba(12, 128, 107, 0.6);
}
.graph__captions--top .graph__caption--primary-1-80 {
  border-color: rgba(12, 128, 107, 0.8);
}
.graph__captions--top .graph__caption--primary-2 {
  border-color: #8acab7;
}
.graph__captions--top .graph__caption--primary-2-l {
  border-color: #e8f4f1;
}
.graph__captions--top .graph__caption--primary-2-d {
  border-color: #42b491;
}
.graph__captions--top .graph__caption--neutral-1 {
  border-color: #050505;
}
.graph__captions--top .graph__caption--neutral-1-l {
  border-color: #f5f5f5;
}
.graph__captions--top .graph__caption--neutral-1-d {
  border-color: #000000;
}
.graph__captions--top .graph__caption--neutral-1-20 {
  border-color: rgba(5, 5, 5, 0.2);
}
.graph__captions--top .graph__caption--neutral-1-30 {
  border-color: rgba(5, 5, 5, 0.3);
}
.graph__captions--top .graph__caption--neutral-1-40 {
  border-color: rgba(5, 5, 5, 0.4);
}
.graph__captions--top .graph__caption--neutral-1-60 {
  border-color: rgba(5, 5, 5, 0.6);
}
.graph__captions--top .graph__caption--neutral-1-80 {
  border-color: rgba(5, 5, 5, 0.8);
}
.graph__captions--top .graph__caption--accent-1 {
  border-color: #fc5c63;
}
.graph__captions--top .graph__caption--accent-2 {
  border-color: #fc847c;
}
.graph__captions--top .graph__caption--accent-3 {
  border-color: #ffaa98;
}
.graph__captions--top .graph__caption--accent-4 {
  border-color: #ffe7d1;
}
.graph__captions--top .graph__caption--accent-4-20 {
  border-color: rgba(255, 231, 209, 0.2);
}
.graph__captions--top .graph__caption--accent-4-30 {
  border-color: rgba(255, 231, 209, 0.3);
}
.graph__captions--top .graph__caption--accent-4-40 {
  border-color: rgba(255, 231, 209, 0.4);
}
.graph__captions--top .graph__caption--accent-4-60 {
  border-color: rgba(255, 231, 209, 0.6);
}
.graph__captions--top .graph__caption--accent-4-80 {
  border-color: rgba(255, 231, 209, 0.8);
}
.graph__captions--top .graph__caption--accent-5 {
  border-color: #ffaa98;
}
.graph__captions--top .graph__caption--accent-1-l {
  border-color: #fff2f3;
}
.graph__captions--top .graph__caption--accent-2-l {
  border-color: #fdb3ae;
}
.graph__captions--top .graph__caption--accent-3-l {
  border-color: #ffd4cb;
}
.graph__captions--top .graph__caption--accent-4-l {
  border-color: #fff4eb;
}
.graph__captions--top .graph__caption--accent-5-l {
  border-color: #ffbfb2;
}
.graph__captions--top .graph__caption--accent-1-d {
  border-color: #ee040f;
}
.graph__captions--top .graph__caption--accent-2-d {
  border-color: #fa2618;
}
.graph__captions--top .graph__caption--accent-3-d {
  border-color: #ff5632;
}
.graph__captions--top .graph__caption--accent-4-d {
  border-color: #ffb26b;
}
.graph__captions--top .graph__caption--accent-5-d {
  border-color: #ff5632;
}
.graph__captions--top .graph__caption--success {
  border-color: #128c74;
}
.graph__captions--top .graph__caption--success-l {
  border-color: #ddefea;
}
.graph__captions--top .graph__caption--success-d {
  border-color: #0c5f4f;
}
.graph__captions--top .graph__caption--warning {
  border-color: #ff8867;
}
.graph__captions--top .graph__caption--warning-d {
  border-color: #e3401b;
}
.graph__captions--top .graph__caption--warning-l {
  border-color: #ffb190;
}
.graph__captions--top .graph__caption--error {
  border-color: #e52626;
}
.graph__captions--top .graph__caption--error-d {
  border-color: #c70808;
}
.graph__captions--top .graph__caption--error-l {
  border-color: #ff7676;
}
.graph__captions--top .graph__caption--inherit {
  border-color: inherit;
}
.graph__captions--top .graph__caption:nth-child(1) {
  border-left-width: 2px;
}
.graph__captions--top .graph__caption:nth-child(2) {
  border-right-width: 2px;
  text-align: right;
}
.graph__captions--top .graph__caption--dimmed {
  opacity: 0.5;
}

.graph__captions--bottom {
  align-items: flex-start;
  margin-bottom: 0;
  margin-top: 8px;
}

.graph__parts--mobile {
  margin-bottom: 16px;
}

.graph__parts__container {
  border-radius: 4px;
  display: flex;
  overflow: hidden;
}

.graph__parts__container + .graph__parts__container {
  margin-top: 8px;
}

.graph__parts__part {
  border-right: 2px solid white;
  box-sizing: border-box;
  cursor: pointer;
  flex-grow: 1;
  height: 16px;
  min-width: 16px;
  transition: opacity 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: opacity;
}
.graph__parts__part--white {
  background-color: #fff;
}
.graph__parts__part--facebook {
  background-color: #4267b2;
}
.graph__parts__part--twitter {
  background-color: #1da1f2;
}
.graph__parts__part--whatsapp {
  background-color: #25d366;
}
.graph__parts__part--primary-1 {
  background-color: #0c806b;
}
.graph__parts__part--primary-1-l {
  background-color: #d2f8d1;
}
.graph__parts__part--primary-1-d {
  background-color: #006855;
}
.graph__parts__part--primary-1-20 {
  background-color: rgba(12, 128, 107, 0.2);
}
.graph__parts__part--primary-1-30 {
  background-color: rgba(12, 128, 107, 0.3);
}
.graph__parts__part--primary-1-40 {
  background-color: rgba(12, 128, 107, 0.4);
}
.graph__parts__part--primary-1-60 {
  background-color: rgba(12, 128, 107, 0.6);
}
.graph__parts__part--primary-1-80 {
  background-color: rgba(12, 128, 107, 0.8);
}
.graph__parts__part--primary-2 {
  background-color: #8acab7;
}
.graph__parts__part--primary-2-l {
  background-color: #e8f4f1;
}
.graph__parts__part--primary-2-d {
  background-color: #42b491;
}
.graph__parts__part--neutral-1 {
  background-color: #050505;
}
.graph__parts__part--neutral-1-l {
  background-color: #f5f5f5;
}
.graph__parts__part--neutral-1-d {
  background-color: #000000;
}
.graph__parts__part--neutral-1-20 {
  background-color: rgba(5, 5, 5, 0.2);
}
.graph__parts__part--neutral-1-30 {
  background-color: rgba(5, 5, 5, 0.3);
}
.graph__parts__part--neutral-1-40 {
  background-color: rgba(5, 5, 5, 0.4);
}
.graph__parts__part--neutral-1-60 {
  background-color: rgba(5, 5, 5, 0.6);
}
.graph__parts__part--neutral-1-80 {
  background-color: rgba(5, 5, 5, 0.8);
}
.graph__parts__part--accent-1 {
  background-color: #fc5c63;
}
.graph__parts__part--accent-2 {
  background-color: #fc847c;
}
.graph__parts__part--accent-3 {
  background-color: #ffaa98;
}
.graph__parts__part--accent-4 {
  background-color: #ffe7d1;
}
.graph__parts__part--accent-4-20 {
  background-color: rgba(255, 231, 209, 0.2);
}
.graph__parts__part--accent-4-30 {
  background-color: rgba(255, 231, 209, 0.3);
}
.graph__parts__part--accent-4-40 {
  background-color: rgba(255, 231, 209, 0.4);
}
.graph__parts__part--accent-4-60 {
  background-color: rgba(255, 231, 209, 0.6);
}
.graph__parts__part--accent-4-80 {
  background-color: rgba(255, 231, 209, 0.8);
}
.graph__parts__part--accent-5 {
  background-color: #ffaa98;
}
.graph__parts__part--accent-1-l {
  background-color: #fff2f3;
}
.graph__parts__part--accent-2-l {
  background-color: #fdb3ae;
}
.graph__parts__part--accent-3-l {
  background-color: #ffd4cb;
}
.graph__parts__part--accent-4-l {
  background-color: #fff4eb;
}
.graph__parts__part--accent-5-l {
  background-color: #ffbfb2;
}
.graph__parts__part--accent-1-d {
  background-color: #ee040f;
}
.graph__parts__part--accent-2-d {
  background-color: #fa2618;
}
.graph__parts__part--accent-3-d {
  background-color: #ff5632;
}
.graph__parts__part--accent-4-d {
  background-color: #ffb26b;
}
.graph__parts__part--accent-5-d {
  background-color: #ff5632;
}
.graph__parts__part--success {
  background-color: #128c74;
}
.graph__parts__part--success-l {
  background-color: #ddefea;
}
.graph__parts__part--success-d {
  background-color: #0c5f4f;
}
.graph__parts__part--warning {
  background-color: #ff8867;
}
.graph__parts__part--warning-d {
  background-color: #e3401b;
}
.graph__parts__part--warning-l {
  background-color: #ffb190;
}
.graph__parts__part--error {
  background-color: #e52626;
}
.graph__parts__part--error-d {
  background-color: #c70808;
}
.graph__parts__part--error-l {
  background-color: #ff7676;
}
.graph__parts__part--inherit {
  background-color: inherit;
}
.graph__parts__part:last-child {
  border-right: none;
}
.graph__parts__part--dimmed {
  opacity: 0.5;
}
