.sponsorship-conditions-page--paragraph {
  margin-bottom: 16px;
}

.sponsorship-conditions-page a {
  text-decoration: underline;
}

.sponsorship-conditions-page ul li {
  list-style-type: lower-alpha;
  list-style-position: inside;
}
.sponsorship-conditions-page ul li ul {
  padding-left: 16px;
}
.sponsorship-conditions-page ul li ul li {
  list-style-type: none;
}
