.textfield--label {
  display: block;
  margin-bottom: 16px;
}

.textfield--default:focus-within,
.textfield--search:focus-within,
.textfield--rounded:focus-within,
.textfield--line:focus-within {
  outline: 1px dashed #0c806b;
}

.textfield--small {
  font-size: 14px;
}

.textfield--medium {
  font-size: 16px;
}

.textfield--large {
  font-size: 24px;
}

.textfield--clear {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  opacity: 0.6;
  background: #fff;
  cursor: pointer;
  padding: 8px 24px;
  height: 100%;
  color: #050505;
}
.textfield--clear:hover {
  opacity: 1;
}
.textfield--clear .icon {
  display: flex;
}

.textfield--label {
  margin-right: 8px;
}

.textfield--error-text {
  text-align: left;
  color: #e52626;
  font-size: 12px;
}

.textfield--default .textfield--error {
  position: absolute;
  top: 100%;
  left: 0;
}

.textfield--hint {
  margin-top: 8px;
  text-align: center;
}
.textfield--hint--correct {
  cursor: pointer;
  text-decoration: underline;
}

.textfield--textarea {
  display: block;
  outline: 0;
  border-radius: 8px;
  border-color: rgba(5, 5, 5, 0.2);
  padding: 8px 16px;
  width: 100%;
  min-height: 90px;
  color: #050505;
  font: inherit;
  resize: none;
}
.textfield--textarea::placeholder {
  color: rgba(5, 5, 5, 0.3);
}
.textfield--textarea::-webkit-input-placeholder {
  color: rgba(5, 5, 5, 0.3);
}
.textfield--textarea:-moz-placeholder {
  color: rgba(5, 5, 5, 0.3);
}
.textfield--textarea::-moz-placeholder {
  color: rgba(5, 5, 5, 0.3);
}
.textfield--textarea:-ms-input-placeholder {
  color: rgba(5, 5, 5, 0.3);
}

.textfield--default,
.textfield--search,
.textfield--rounded,
.textfield--line {
  display: inline-block;
  position: relative;
  border-bottom: 2px solid #0c806b;
  padding: 0 8px;
  width: 100%;
  height: 48px;
  line-height: 46px;
  color: rgba(5, 5, 5, 0.3);
}
.textfield--default .textfield--input,
.textfield--search .textfield--input,
.textfield--rounded .textfield--input,
.textfield--line .textfield--input {
  outline: 0;
  border: 0;
  background: initial;
  width: 100%;
  text-align: left;
  color: #050505;
  font-family: Maison Neue;
  font-size: inherit;
  font-weight: 500;
}
.textfield--default .textfield--input::placeholder,
.textfield--search .textfield--input::placeholder,
.textfield--rounded .textfield--input::placeholder,
.textfield--line .textfield--input::placeholder {
  color: rgba(5, 5, 5, 0.3);
}
.textfield--default .textfield--input::-webkit-input-placeholder,
.textfield--search .textfield--input::-webkit-input-placeholder,
.textfield--rounded .textfield--input::-webkit-input-placeholder,
.textfield--line .textfield--input::-webkit-input-placeholder {
  color: rgba(5, 5, 5, 0.3);
}
.textfield--default .textfield--input:-moz-placeholder,
.textfield--search .textfield--input:-moz-placeholder,
.textfield--rounded .textfield--input:-moz-placeholder,
.textfield--line .textfield--input:-moz-placeholder {
  color: rgba(5, 5, 5, 0.3);
}
.textfield--default .textfield--input::-moz-placeholder,
.textfield--search .textfield--input::-moz-placeholder,
.textfield--rounded .textfield--input::-moz-placeholder,
.textfield--line .textfield--input::-moz-placeholder {
  color: rgba(5, 5, 5, 0.3);
}
.textfield--default .textfield--input:-ms-input-placeholder,
.textfield--search .textfield--input:-ms-input-placeholder,
.textfield--rounded .textfield--input:-ms-input-placeholder,
.textfield--line .textfield--input:-ms-input-placeholder {
  color: rgba(5, 5, 5, 0.3);
}
.textfield--icon.textfield--default .icon,
.textfield--icon.textfield--search .icon,
.textfield--icon.textfield--rounded .icon,
.textfield--icon.textfield--line .icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 16px;
}
.textfield--icon.textfield--default .textfield--input,
.textfield--icon.textfield--search .textfield--input,
.textfield--icon.textfield--rounded .textfield--input,
.textfield--icon.textfield--line .textfield--input {
  padding-left: 32px;
}

.textfield--white {
  border-bottom-color: #fff;
}

.textfield--facebook {
  border-bottom-color: #4267b2;
}

.textfield--twitter {
  border-bottom-color: #1da1f2;
}

.textfield--whatsapp {
  border-bottom-color: #25d366;
}

.textfield--primary-1 {
  border-bottom-color: #0c806b;
}

.textfield--primary-1-l {
  border-bottom-color: #d2f8d1;
}

.textfield--primary-1-d {
  border-bottom-color: #006855;
}

.textfield--primary-1-20 {
  border-bottom-color: rgba(12, 128, 107, 0.2);
}

.textfield--primary-1-30 {
  border-bottom-color: rgba(12, 128, 107, 0.3);
}

.textfield--primary-1-40 {
  border-bottom-color: rgba(12, 128, 107, 0.4);
}

.textfield--primary-1-60 {
  border-bottom-color: rgba(12, 128, 107, 0.6);
}

.textfield--primary-1-80 {
  border-bottom-color: rgba(12, 128, 107, 0.8);
}

.textfield--primary-2 {
  border-bottom-color: #8acab7;
}

.textfield--primary-2-l {
  border-bottom-color: #e8f4f1;
}

.textfield--primary-2-d {
  border-bottom-color: #42b491;
}

.textfield--neutral-1 {
  border-bottom-color: #050505;
}

.textfield--neutral-1-l {
  border-bottom-color: #f5f5f5;
}

.textfield--neutral-1-d {
  border-bottom-color: #000000;
}

.textfield--neutral-1-20 {
  border-bottom-color: rgba(5, 5, 5, 0.2);
}

.textfield--neutral-1-30 {
  border-bottom-color: rgba(5, 5, 5, 0.3);
}

.textfield--neutral-1-40 {
  border-bottom-color: rgba(5, 5, 5, 0.4);
}

.textfield--neutral-1-60 {
  border-bottom-color: rgba(5, 5, 5, 0.6);
}

.textfield--neutral-1-80 {
  border-bottom-color: rgba(5, 5, 5, 0.8);
}

.textfield--accent-1 {
  border-bottom-color: #fc5c63;
}

.textfield--accent-2 {
  border-bottom-color: #fc847c;
}

.textfield--accent-3 {
  border-bottom-color: #ffaa98;
}

.textfield--accent-4 {
  border-bottom-color: #ffe7d1;
}

.textfield--accent-4-20 {
  border-bottom-color: rgba(255, 231, 209, 0.2);
}

.textfield--accent-4-30 {
  border-bottom-color: rgba(255, 231, 209, 0.3);
}

.textfield--accent-4-40 {
  border-bottom-color: rgba(255, 231, 209, 0.4);
}

.textfield--accent-4-60 {
  border-bottom-color: rgba(255, 231, 209, 0.6);
}

.textfield--accent-4-80 {
  border-bottom-color: rgba(255, 231, 209, 0.8);
}

.textfield--accent-5 {
  border-bottom-color: #ffaa98;
}

.textfield--accent-1-l {
  border-bottom-color: #fff2f3;
}

.textfield--accent-2-l {
  border-bottom-color: #fdb3ae;
}

.textfield--accent-3-l {
  border-bottom-color: #ffd4cb;
}

.textfield--accent-4-l {
  border-bottom-color: #fff4eb;
}

.textfield--accent-5-l {
  border-bottom-color: #ffbfb2;
}

.textfield--accent-1-d {
  border-bottom-color: #ee040f;
}

.textfield--accent-2-d {
  border-bottom-color: #fa2618;
}

.textfield--accent-3-d {
  border-bottom-color: #ff5632;
}

.textfield--accent-4-d {
  border-bottom-color: #ffb26b;
}

.textfield--accent-5-d {
  border-bottom-color: #ff5632;
}

.textfield--success {
  border-bottom-color: #128c74;
}

.textfield--success-l {
  border-bottom-color: #ddefea;
}

.textfield--success-d {
  border-bottom-color: #0c5f4f;
}

.textfield--warning {
  border-bottom-color: #ff8867;
}

.textfield--warning-d {
  border-bottom-color: #e3401b;
}

.textfield--warning-l {
  border-bottom-color: #ffb190;
}

.textfield--error {
  border-bottom-color: #e52626;
}

.textfield--error-d {
  border-bottom-color: #c70808;
}

.textfield--error-l {
  border-bottom-color: #ff7676;
}

.textfield--inherit {
  border-bottom-color: inherit;
}

.textfield--default {
  transition: border-bottom-color 300ms linear;
}
.textfield--default.textfield--icon .icon {
  transition: all 300ms linear;
}
.textfield--default.textfield--icon .textfield--input {
  padding-left: 40px;
}

.textfield--search {
  border-bottom: 0;
}

.textfield--rounded {
  box-shadow: 0 4px 20px 0 rgba(#050505, 0.15);
  border: 0;
  border-radius: 40px;
  background: #fff;
  padding-left: 24px;
  line-height: 48px;
}

.textfield--sentence {
  display: inline;
  position: relative;
  border-bottom: 2px dashed #0c806b;
  border-radius: 0;
  padding: 4px 8px 7px;
  min-width: 8px;
  font-weight: 600;
  display: inline-block;
  position: relative;
}
@media screen and (min-width: 768px) {
  .textfield--sentence {
    display: inline-block;
    padding: 0 8px;
    height: 32px;
    line-height: 32px;
  }
}
.textfield--sentence input {
  border: 0;
  padding: 0;
  text-align: center;
  color: #0c806b;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
}
.textfield--sentence input:focus {
  outline: none;
}
.textfield--sentence input::placeholder {
  color: rgba(5, 5, 5, 0.3);
  font-weight: 400;
}
.textfield--sentence input::-webkit-input-placeholder {
  color: rgba(5, 5, 5, 0.3);
  font-weight: 400;
}
.textfield--sentence input:-moz-placeholder {
  color: rgba(5, 5, 5, 0.3);
  font-weight: 400;
}
.textfield--sentence input::-moz-placeholder {
  color: rgba(5, 5, 5, 0.3);
  font-weight: 400;
}
.textfield--sentence input:-ms-input-placeholder {
  color: rgba(5, 5, 5, 0.3);
  font-weight: 400;
}
.textfield--sentence-is-valid {
  border-style: solid;
}
.textfield--sentence input {
  width: 100%;
}
.textfield--sentence .textfield--input-is-valid {
  border-bottom-style: solid;
}

.textfield--line {
  transition: border 250ms ease-in-out;
  border: 1px solid rgba(5, 5, 5, 0.2);
  border-radius: 8px;
  background: #fff;
  padding: 0 16px;
}
.textfield--line:focus-within {
  outline: 0;
  border-color: #0c806b;
}
.textfield--line.textfield--small {
  height: 40px;
  line-height: 38px;
}
.textfield--line:before {
  left: 16px;
}

.textfield--large-border {
  border-width: 2px;
  height: 56px;
  line-height: 54px;
}
@media only screen and (min-width: 768px) {
  .textfield--large-border {
    height: 48px;
    line-height: 46px;
  }
}
.textfield--large-border.textfield--focused--white {
  border-color: #fff;
}
.textfield--large-border.textfield--focused--facebook {
  border-color: #4267b2;
}
.textfield--large-border.textfield--focused--twitter {
  border-color: #1da1f2;
}
.textfield--large-border.textfield--focused--whatsapp {
  border-color: #25d366;
}
.textfield--large-border.textfield--focused--primary-1 {
  border-color: #0c806b;
}
.textfield--large-border.textfield--focused--primary-1-l {
  border-color: #d2f8d1;
}
.textfield--large-border.textfield--focused--primary-1-d {
  border-color: #006855;
}
.textfield--large-border.textfield--focused--primary-1-20 {
  border-color: rgba(12, 128, 107, 0.2);
}
.textfield--large-border.textfield--focused--primary-1-30 {
  border-color: rgba(12, 128, 107, 0.3);
}
.textfield--large-border.textfield--focused--primary-1-40 {
  border-color: rgba(12, 128, 107, 0.4);
}
.textfield--large-border.textfield--focused--primary-1-60 {
  border-color: rgba(12, 128, 107, 0.6);
}
.textfield--large-border.textfield--focused--primary-1-80 {
  border-color: rgba(12, 128, 107, 0.8);
}
.textfield--large-border.textfield--focused--primary-2 {
  border-color: #8acab7;
}
.textfield--large-border.textfield--focused--primary-2-l {
  border-color: #e8f4f1;
}
.textfield--large-border.textfield--focused--primary-2-d {
  border-color: #42b491;
}
.textfield--large-border.textfield--focused--neutral-1 {
  border-color: #050505;
}
.textfield--large-border.textfield--focused--neutral-1-l {
  border-color: #f5f5f5;
}
.textfield--large-border.textfield--focused--neutral-1-d {
  border-color: #000000;
}
.textfield--large-border.textfield--focused--neutral-1-20 {
  border-color: rgba(5, 5, 5, 0.2);
}
.textfield--large-border.textfield--focused--neutral-1-30 {
  border-color: rgba(5, 5, 5, 0.3);
}
.textfield--large-border.textfield--focused--neutral-1-40 {
  border-color: rgba(5, 5, 5, 0.4);
}
.textfield--large-border.textfield--focused--neutral-1-60 {
  border-color: rgba(5, 5, 5, 0.6);
}
.textfield--large-border.textfield--focused--neutral-1-80 {
  border-color: rgba(5, 5, 5, 0.8);
}
.textfield--large-border.textfield--focused--accent-1 {
  border-color: #fc5c63;
}
.textfield--large-border.textfield--focused--accent-2 {
  border-color: #fc847c;
}
.textfield--large-border.textfield--focused--accent-3 {
  border-color: #ffaa98;
}
.textfield--large-border.textfield--focused--accent-4 {
  border-color: #ffe7d1;
}
.textfield--large-border.textfield--focused--accent-4-20 {
  border-color: rgba(255, 231, 209, 0.2);
}
.textfield--large-border.textfield--focused--accent-4-30 {
  border-color: rgba(255, 231, 209, 0.3);
}
.textfield--large-border.textfield--focused--accent-4-40 {
  border-color: rgba(255, 231, 209, 0.4);
}
.textfield--large-border.textfield--focused--accent-4-60 {
  border-color: rgba(255, 231, 209, 0.6);
}
.textfield--large-border.textfield--focused--accent-4-80 {
  border-color: rgba(255, 231, 209, 0.8);
}
.textfield--large-border.textfield--focused--accent-5 {
  border-color: #ffaa98;
}
.textfield--large-border.textfield--focused--accent-1-l {
  border-color: #fff2f3;
}
.textfield--large-border.textfield--focused--accent-2-l {
  border-color: #fdb3ae;
}
.textfield--large-border.textfield--focused--accent-3-l {
  border-color: #ffd4cb;
}
.textfield--large-border.textfield--focused--accent-4-l {
  border-color: #fff4eb;
}
.textfield--large-border.textfield--focused--accent-5-l {
  border-color: #ffbfb2;
}
.textfield--large-border.textfield--focused--accent-1-d {
  border-color: #ee040f;
}
.textfield--large-border.textfield--focused--accent-2-d {
  border-color: #fa2618;
}
.textfield--large-border.textfield--focused--accent-3-d {
  border-color: #ff5632;
}
.textfield--large-border.textfield--focused--accent-4-d {
  border-color: #ffb26b;
}
.textfield--large-border.textfield--focused--accent-5-d {
  border-color: #ff5632;
}
.textfield--large-border.textfield--focused--success {
  border-color: #128c74;
}
.textfield--large-border.textfield--focused--success-l {
  border-color: #ddefea;
}
.textfield--large-border.textfield--focused--success-d {
  border-color: #0c5f4f;
}
.textfield--large-border.textfield--focused--warning {
  border-color: #ff8867;
}
.textfield--large-border.textfield--focused--warning-d {
  border-color: #e3401b;
}
.textfield--large-border.textfield--focused--warning-l {
  border-color: #ffb190;
}
.textfield--large-border.textfield--focused--error {
  border-color: #e52626;
}
.textfield--large-border.textfield--focused--error-d {
  border-color: #c70808;
}
.textfield--large-border.textfield--focused--error-l {
  border-color: #ff7676;
}
.textfield--large-border.textfield--focused--inherit {
  border-color: inherit;
}
.textfield--large-border.textfield:focus-within {
  outline: 0;
}
