.template--default {
  background: #f5f5f5;
  min-height: 100%;
}
.template--default--heading {
  padding-top: 32px;
}
.template--default--description {
  margin-bottom: 16px;
  padding: 8px 16px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .template--default--description {
    padding: 32px 24px;
  }
}
@media screen and (min-width: 1024px) {
  .template--default--description {
    padding-left: 0;
    padding-right: 0;
  }
}
