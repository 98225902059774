.optimize--back {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.optimize--back--icon {
  margin-right: 24px;
}

@media screen and (min-width: 1024px) {
  .optimize--cards {
    margin: -24px;
  }
}
