.pretto-logo {
  display: block;
}
.pretto-logo--small {
  height: 16px;
}
.pretto-logo--medium {
  height: 24px;
}
.pretto-logo--large {
  height: 32px;
}
