.dialog--overlay {
  background-color: rgba(5, 5, 5, 0.8);
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  padding: 24px;
  height: 100vh;
  /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}
@media screen and (min-width: 768px) {
  .dialog--overlay {
    backdrop-filter: blur(14px);
  }
}

.dialog--content {
  position: initial;
  outline: none;
  border: 0;
  border-radius: 8px;
  background: #fff;
  width: 100%;
  max-width: 600px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.dialog--content p:not(:last-child) {
  margin-bottom: 16px;
}

body.ReactModal__Body--open {
  overflow: hidden;
}

.dialog--content {
  transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transform: translateY(-2em);
  opacity: 0;
}

.dialog--content-after-open {
  transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transform: translateY(0);
  opacity: 1;
}

.dialog--content-before-close {
  transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transform: translateY(-2em);
  opacity: 0;
}

.dialog--overlay {
  transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  opacity: 0;
}

.dialog--overlay-after-open {
  opacity: 1;
}

.dialog--overlay-before-close {
  opacity: 0;
}

@media only screen and (max-width: 767px) {
  .dialog--overlay {
    padding: 0;
  }
  .dialog--content {
    border-radius: 0;
    width: 100%;
    max-width: initial;
    height: 100%;
  }
}

.dialog--overflow-initial {
  overflow: initial;
}
