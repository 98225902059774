.datefield--input:focus-within {
  outline: 1px dashed #0c806b;
}

.datefield--input {
  display: inline;
  position: relative;
  border-bottom: 2px dashed #0c806b;
  border-radius: 0;
  padding: 4px 8px 7px;
  min-width: 8px;
  font-weight: 600;
  display: inline-block;
  padding: 0;
  line-height: 32px;
  letter-spacing: normal;
}
@media screen and (min-width: 768px) {
  .datefield--input {
    display: inline-block;
    padding: 0 8px;
    height: 32px;
    line-height: 32px;
  }
}
.datefield--input input {
  border: 0;
  padding: 0;
  text-align: center;
  color: #0c806b;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
}
.datefield--input input:focus {
  outline: none;
}
.datefield--input input::placeholder {
  color: rgba(5, 5, 5, 0.3);
  font-weight: 400;
}
.datefield--input input::-webkit-input-placeholder {
  color: rgba(5, 5, 5, 0.3);
  font-weight: 400;
}
.datefield--input input:-moz-placeholder {
  color: rgba(5, 5, 5, 0.3);
  font-weight: 400;
}
.datefield--input input::-moz-placeholder {
  color: rgba(5, 5, 5, 0.3);
  font-weight: 400;
}
.datefield--input input:-ms-input-placeholder {
  color: rgba(5, 5, 5, 0.3);
  font-weight: 400;
}
.datefield--input-is-valid {
  border-style: solid;
}
.datefield--input--white {
  border-bottom-color: #fff;
}
.datefield--input--facebook {
  border-bottom-color: #4267b2;
}
.datefield--input--twitter {
  border-bottom-color: #1da1f2;
}
.datefield--input--whatsapp {
  border-bottom-color: #25d366;
}
.datefield--input--primary-1 {
  border-bottom-color: #0c806b;
}
.datefield--input--primary-1-l {
  border-bottom-color: #d2f8d1;
}
.datefield--input--primary-1-d {
  border-bottom-color: #006855;
}
.datefield--input--primary-1-20 {
  border-bottom-color: rgba(12, 128, 107, 0.2);
}
.datefield--input--primary-1-30 {
  border-bottom-color: rgba(12, 128, 107, 0.3);
}
.datefield--input--primary-1-40 {
  border-bottom-color: rgba(12, 128, 107, 0.4);
}
.datefield--input--primary-1-60 {
  border-bottom-color: rgba(12, 128, 107, 0.6);
}
.datefield--input--primary-1-80 {
  border-bottom-color: rgba(12, 128, 107, 0.8);
}
.datefield--input--primary-2 {
  border-bottom-color: #8acab7;
}
.datefield--input--primary-2-l {
  border-bottom-color: #e8f4f1;
}
.datefield--input--primary-2-d {
  border-bottom-color: #42b491;
}
.datefield--input--neutral-1 {
  border-bottom-color: #050505;
}
.datefield--input--neutral-1-l {
  border-bottom-color: #f5f5f5;
}
.datefield--input--neutral-1-d {
  border-bottom-color: #000000;
}
.datefield--input--neutral-1-20 {
  border-bottom-color: rgba(5, 5, 5, 0.2);
}
.datefield--input--neutral-1-30 {
  border-bottom-color: rgba(5, 5, 5, 0.3);
}
.datefield--input--neutral-1-40 {
  border-bottom-color: rgba(5, 5, 5, 0.4);
}
.datefield--input--neutral-1-60 {
  border-bottom-color: rgba(5, 5, 5, 0.6);
}
.datefield--input--neutral-1-80 {
  border-bottom-color: rgba(5, 5, 5, 0.8);
}
.datefield--input--accent-1 {
  border-bottom-color: #fc5c63;
}
.datefield--input--accent-2 {
  border-bottom-color: #fc847c;
}
.datefield--input--accent-3 {
  border-bottom-color: #ffaa98;
}
.datefield--input--accent-4 {
  border-bottom-color: #ffe7d1;
}
.datefield--input--accent-4-20 {
  border-bottom-color: rgba(255, 231, 209, 0.2);
}
.datefield--input--accent-4-30 {
  border-bottom-color: rgba(255, 231, 209, 0.3);
}
.datefield--input--accent-4-40 {
  border-bottom-color: rgba(255, 231, 209, 0.4);
}
.datefield--input--accent-4-60 {
  border-bottom-color: rgba(255, 231, 209, 0.6);
}
.datefield--input--accent-4-80 {
  border-bottom-color: rgba(255, 231, 209, 0.8);
}
.datefield--input--accent-5 {
  border-bottom-color: #ffaa98;
}
.datefield--input--accent-1-l {
  border-bottom-color: #fff2f3;
}
.datefield--input--accent-2-l {
  border-bottom-color: #fdb3ae;
}
.datefield--input--accent-3-l {
  border-bottom-color: #ffd4cb;
}
.datefield--input--accent-4-l {
  border-bottom-color: #fff4eb;
}
.datefield--input--accent-5-l {
  border-bottom-color: #ffbfb2;
}
.datefield--input--accent-1-d {
  border-bottom-color: #ee040f;
}
.datefield--input--accent-2-d {
  border-bottom-color: #fa2618;
}
.datefield--input--accent-3-d {
  border-bottom-color: #ff5632;
}
.datefield--input--accent-4-d {
  border-bottom-color: #ffb26b;
}
.datefield--input--accent-5-d {
  border-bottom-color: #ff5632;
}
.datefield--input--success {
  border-bottom-color: #128c74;
}
.datefield--input--success-l {
  border-bottom-color: #ddefea;
}
.datefield--input--success-d {
  border-bottom-color: #0c5f4f;
}
.datefield--input--warning {
  border-bottom-color: #ff8867;
}
.datefield--input--warning-d {
  border-bottom-color: #e3401b;
}
.datefield--input--warning-l {
  border-bottom-color: #ffb190;
}
.datefield--input--error {
  border-bottom-color: #e52626;
}
.datefield--input--error-d {
  border-bottom-color: #c70808;
}
.datefield--input--error-l {
  border-bottom-color: #ff7676;
}
.datefield--input--inherit {
  border-bottom-color: inherit;
}
@media screen and (min-width: 768px) {
  .datefield--input {
    padding: 0;
  }
}
.datefield--input--textfield {
  border-bottom-style: solid;
  width: 100px;
}
.datefield--input--textfield input {
  width: 100%;
  text-align: right;
}
.datefield--input--form {
  border-bottom-style: solid;
  padding-left: 8px;
  width: 100%;
  height: 40px;
  line-height: 40px;
}
.datefield--input--form input {
  background: transparent;
  width: 100% !important;
  text-align: left;
  color: #050505;
  font-weight: normal;
}
.datefield--input--editable-field {
  border-bottom-style: solid;
  height: 48px;
  line-height: 48px;
}
.datefield--input--editable-field input {
  background: transparent;
  color: inherit;
  font: inherit;
  font-weight: 500;
}
