.swipe-action {
  position: relative;
  overflow: hidden;
}
.swipe-action--content {
  position: relative;
  transition: right linear 300ms;
  width: 100%;
}
.swipe-action--delete {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  align-items: center;
  justify-content: center;
  transition: left linear 300ms;
  background: #e52626;
  padding: 16px;
  width: 50%;
  color: #fff;
}
