/* Maison Neue — Black — normal — latin */
@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: local('Montserrat Black'), local('Montserrat-Black'), url('./Montserrat-Black.ttf') format('ttf');
}

/* Montserrat — Bold — normal — latin */
@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url('./Montserrat-Bold.ttf') format('ttf');
}
